// =========== Task

// Individual tasks
div.task {
  .mixin-clearfix;
  margin: 2px 0 0;

  &.is-locked {
    span.task--summary-icon:hover {
      cursor: not-allowed;
    }
  }

  &.is-actief div.task--summary {
    cursor: pointer;
  }

  &.ui-sortable-helper {
    outline: 2px solid @color-neutral-10;
  }

  &.has-popout.is-open {
    margin-bottom: (@spacing/2);
  }

  .popout--options ul {
    margin: 0;
  }
}

div.task--add {
  line-height: 3rem;
  height: 3rem;
  max-height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  a {
    color: @color-neutral-60;
    display: inline-block;
    font-weight: 400;

    &:hover {
      color: @color-neutral-70;
    }
  }

  i {
    position: relative;
    top: 0.15rem;
  }
}

div.task--summary {
  .mixin-display-flex;
  .mixin-flex-wrap(nowrap);

  background: @white;
  line-height: 3rem;
  height: 3rem;
  max-height: 3rem;

  &.is-group-blue:before,
  &.is-group-dark:before,
  &.is-group-geen:before,
  &.is-group-green:before,
  &.is-group-red:before,
  &.is-group-pink-lavender:before,
  &.is-group-crimson-red:before,
  &.is-group-middle-yellow:before,
  &.is-group-android-green:before,
  &.is-group-sage:before,
  &.is-group-artichoke:before {
    min-width: 1rem;
    max-width: 1rem;
    content: " ";
  }

  &.is-group-green:before {
    background: @color-success;
  }

  &.is-group-red:before {
    background: @color-alert;
  }

  &.is-group-blue:before {
    background: @color-brand-50;
  }

  &.is-group-dark:before {
    background: @color-neutral-50;
  }

  &.is-group-pink-lavender:before {
    background: @color-pink-lavender;
  }

  &.is-group-crimson-red:before {
    background: @color-crimson-red;
  }

  &.is-group-middle-yellow:before {
    background: @color-middle-yellow;
  }

  &.is-group-android-green:before {
    background: @color-android-green;
  }

  &.is-group-sage:before {
    background: @color-sage;
  }

  &.is-group-artichoke:before {
    background: @color-artichoke;
  }

  &.task--summary-larger {
    height: auto;
    max-height: none;
  }

  p {
    .mixin-flex(1 1 auto);
    -webkit-box-flex: 1;
    padding: 0 0.75rem;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    a {
      color: inherit;
      font-weight: inherit;
    }
  }

  small.task--summary-meta {
    .mixin-flex(0 0 auto);
    -webkit-box-flex: 0;
    text-align: center;
    background: @white;
    padding: 0 0.75rem 0 0.5rem;
    min-width: 2.3rem;
    max-width: 25rem;
    overflow: hidden;
    white-space: nowrap;

    .vault--download i {
      padding-left: 1.25rem;
    }

    > span {
      color: @color-neutral-60;
    }
  }

  span.task--summary-icon {
    //hetzelfde als .projectgroup--student-icon
    .mixin-flex(0 0 auto);
    -webkit-box-flex: 0;
    text-align: center;
    width: 3em;
    color: @color-neutral-30;

    &:hover i.expand-vertically-1 {
      color: @color-neutral-60;
    }
  }

  span.task--summary-icon i.lock-1 {
    display: none;
  }

  span.task--summary-flag {
    font-size: 1.75rem;
    padding: 0 0.75rem 0 0.5rem;
  }

  span.sortable--handle {
    //hetzelfde als .projectgroup--student-icon
    display: none;
    cursor: ns-resize!important;

    &:active,
    &:focus {
      cursor: ns-resize;
    }

  }

  i {
    position: relative;
    top: 1px;
  }

  i.expand-vertically-1:before {
    display: inline-block;
  }

  .popout--emotion {
    width: 2rem;
    height: 2rem;

    i:before {
      font-size: 2rem;
    }
  }

  .task--summary-emotion {
    .mixin-flex(0 0 auto);
    width: 2.5rem;
    height: 3rem;
    padding-top: 0.5rem;
    border-radius: @border-radius-2;

    i {
      .mixin-display-flex;
      .mixin-align-items(center);
      text-align: center;
      color: @white;
      width: 100%;

      &:before {
        font-size: 2rem;
        background: @color-neutral-50;
        border-radius: @border-radius-1;
      }
    }
  }

  .task--summary-list {
	padding: 0.5rem .75rem 0.5rem .5rem;
    display: flex;
    flex-direction: column;
    line-height: 2rem;
    align-items: flex-end;
  }
}

div.tasks.is-sortable {
  span.sortable--handle {
    display: block;
  }

  span.task--summary-icon i.lock-1 {
    display: block;
  }
}

div.task--empty {
  display: none;

  &:first-of-type {
    display: block;
  }

  > div {
    background: @color-neutral-10;
    font-style: italic;
    border: dashed 1px @color-neutral-30;
    color: @color-neutral-40;
    .mixin-flex(1 1 auto);
    -webkit-box-flex: 1;
    padding: 0 0.75rem;
    line-height: 3rem;
  }
}

div.task#extensionStatus {
  .task--summary {
    display: none;
  }

  &.is-notinstalled {
    .when-notinstalled {
      .mixin-display-flex;
    }
  }

  &.is-notlinked {
    .when-notlinked {
      .mixin-display-flex;
    }
  }

  &.is-linked {
    .when-linked {
      .mixin-display-flex;
    }
  }
}
