@media @breakpoint-one {
  .auditrecord--panel li {
    > * {
      &.auditrecord--datetime {
        flex-grow: 0;
        flex-basis: 16rem;
        padding-left: @spacing;

        > * {
          &.auditrecord--date {
            float: left;
          }

          &.auditrecord--repeating--date {
            visibility: hidden;
          }

          &.auditrecord--time {
            float: right;
          }
        }
      }

      &.auditrecord--description {
        flex-grow: 1;
        white-space: normal;
        overflow: visible;
        display: block;
        padding-left: @spacing;
      }
    }
  }
}

@media @before-breakpoint-one {
  .auditrecord--panel li {
    > * {
      &.auditrecord--datetime {
        display: block;

        > * {
          &.auditrecord--date {
            display: block;
            float: left;
          }

          &.auditrecord--repeating--date {
            visibility: visible;
          }

          &.auditrecord--time {
            float: right;
            display: block;
          }
        }
      }

      &.auditrecord--description {
        white-space: normal;
        overflow: visible;
        display: block;
      }
    }
  }
}
