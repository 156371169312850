// =========== Label

.include-label {
  border-radius: @border-radius-1;
  font-size: @font-size-10;
  font-weight: 700;
  padding: 0.15rem 0.3rem;
  text-transform: uppercase;
}

.label {
  .include-label;
  color: @white;
  background: @color-neutral-50;
  -webkit-font-smoothing: antialiased;
}

.label--contrast {
  .include-label;
  color: @white;
  background-color: @color-neutral-60;
  -webkit-font-smoothing: antialiased;
}

.label--soft {
  .include-label;
  color: @color-neutral-70;
  background: @color-neutral-30;
}

.label--success {
  .include-label;
  color: @white;
  background-color: @color-success;
  -webkit-font-smoothing: antialiased;
}

.label--warning {
  .include-label;
  color: @white;
  background-color: @color-warning;
  -webkit-font-smoothing: antialiased;
}

.label--alert {
  .include-label;
  color: @white;
  background-color: @color-alert;
  -webkit-font-smoothing: antialiased;
}
