// =========== Grid

// Use '@include grid(12, 2)' to make a column.
// The first unit (12) is the column width.
// The second unit (2) is the margin on both sides.
// The margin will be auto substracted from the column width.
// So don't recalculate the column width if you change your margin.

// To set different margins on both sides use '@include grid(12, 2, 3)'.
// You can also use negative margins.

@grid-column: (100% / @grid-columns);

.mixin-col(@n: 1) {
  float: left;
  width: (@n * @grid-column);
}

.mixin-prepend(@n: 1) {
  margin-left: @grid-column * @n;
}

.mixin-append(@n: 1) {
  margin-right: @grid-column * @n;
}

.mixin-grid(@cols: 1, @grid-margin-left: 0, @grid-margin-right: 0) {
  .mixin-col(@cols - (@grid-margin-left + @grid-margin-right));
  .mixin-prepend(@grid-margin-left);
  .mixin-append(@grid-margin-right);
}

// Use this mixin to reset your grid by example for your mobile articles
.mixin-grid-reset {
  clear: both;
  float: none;
  margin-left: 0;
  margin-right: 0;
  width: auto;
}

// The clearfix mixin is necessary for the floats
.mixin-clearfix {
  &:after,
  &:before {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}
