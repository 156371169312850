// =========== Header-tabs

.header-tabs {
  position: relative;
  background: @white;
  text-align: center;
  max-height: 3rem;
  overflow-y: hidden;
  -webkit-transition: max-height 200ms ease-out;
  transition: max-height 200ms ease-out;

  @media @breakpoint-two {
    .mixin-flex-wrap(nowrap);
    overflow-y: visible;
    padding: 0;
    max-height: none;
    height: 3rem;
  }

  ul {

    list-style: none;
    margin: 0 auto;
    font-size: 0;
    background: @white;

    @media @breakpoint-two {
      text-align: center;
      border-bottom: 0;
    }
  }

  &.is-open {
    ul {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
  }

  li {

    @media @breakpoint-two {
      display: inline-block;
    }
  }

  li:first-child {
    display: block;

    @media @breakpoint-two {
      display: inline-block;

      a {
        border-left: 1px solid @color-neutral-10;
      }
    }

  }

  li.is-selected a {
    color: @color-brand-50;

    @media @breakpoint-two {
      background: @color-neutral-10;
    }
  }

  a {
    display: block;
    color: @color-neutral-50;
    padding: (@spacing / 2);
    font-size: @font-size-30;

    @media @breakpoint-two {
      padding: 0;
      border-right: 1px solid @color-neutral-10;
      line-height: 3rem;
      padding-left: (@spacing / 2);
      padding-right: (@spacing / 2);

      .mixin-transition(~"color 50ms, background 50ms");

      &:hover {
        color: @color-neutral-70;
        background: @color-neutral-10;
      }
    }
  }
}

.header-tabs--toggle {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  padding: 0.9rem 1.5rem 0.9rem 1rem;

  @media @breakpoint-two {
    display: none;
  }
}

.l-container.tabs-none {
  .header-tabs {
    display: none;
  }
}
