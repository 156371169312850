// =========== List

ul.list {
  list-style: none;
  margin-left: 0;

  li {
    border-bottom: 1px solid @color-neutral-30;
    padding: 0.5rem;
  }
}

ul.list--bullet {
  list-style: disc;
  margin-left: 1.5rem;

  &.list--in-text {
     margin-top: -1rem;
  }
}

ul.list--with-links {
  li {
    .mixin-display-flex;
    
    span {
      flex-grow: 1;
    }

    a {
      flex-grow: 0;
      flex-shrink: 0;
      padding-left: (@spacing/2);
    }
  }
}
