// ============ Organizational units
@media @before-breakpoint-three {
  .orgunits--panel {
    .orgunits--description {
      display: none;
    }
  }
}

@media @breakpoint-one {
  .orgunits--panel li {
    > * {
      &.orgunits--add {
        flex-grow: 0;
        flex-basis: 4rem;
        text-align: center;

        a {
          padding: 0 0.35rem;
        }
      }
    
      &.orgunits--name {
        flex-grow: 3;
      }

      &.orgunits--description {
        flex-grow: 5;
        padding-right: @spacing;
        white-space: normal;
        overflow: visible;
      }
 
      &.orgunits--edit {
        display: none;
      }
    }
  }
}

.orgunits--panel li {
  > * {
    &.orgunits--name {
      span.orgunits--depth-0 {
        padding-left: 0rem;
      }
      span.orgunits--depth-1 {
        padding-left: 1.5rem;
      }
      span.orgunits--depth-2 {
        padding-left: 3rem;
      }
      span.orgunits--depth-3 {
        padding-left: 4.5rem;
      }
      span.orgunits--depth-4 {
        padding-left: 6rem;
      }
    }
  }
}