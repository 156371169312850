// =========== Table paging

.paging {
  .mixin-display-flex;
  padding: @spacing 0 (@spacing/2);
  max-width: 30rem;
  margin: 0 auto;

  @media @breakpoint-three {
    padding-bottom: 0;
  }

  a {
    text-align: center;

    &[disabled] {
      color: @color-neutral-30;
      cursor: default;
    }
  }

  > span,
  > a {
    .mixin-flex(1 1 0);
  }

  .goto a {
    display: inline-block;
    width: 100%;
  }
}
