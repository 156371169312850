// =========== Signal

.referal--list,
.signal--wrapper {
	margin-bottom: @spacing;
	position: relative;
}

.referal,
.signal {
	.mixin-display-flex;
	.mixin-justify-content(flex-start);
	.mixin-align-items(center);
	.mixin-flex-direction(row);
	margin-bottom: 2px;
	background: @white;

	figure {
		.mixin-flex(0 0 auto);
		margin-left: 0.75rem;
		height: 3rem;
		width: 3rem;
		border-radius: @border-radius-1;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	> small {
		margin-left: 1rem;
	}

	> div,
	> a {
		font-weight: normal;
		.mixin-flex(1 1 auto);
		-webkit-box-flex: 1;
		padding: 0.75rem;
	}

	[class^=label--] {
		margin-right: 0.15rem;
	}
}

.referal--title,
h3.signal--title {
	font-size: @font-size-30;
	margin: 0;
	color: @color-neutral-60;
}

a.signal--click-icon,
div.referal--click-icon,
div.signal--click-icon {
	.mixin-flex(0 0 auto);
	color: @color-neutral-40;
	vertical-align: middle;
	height: 100%;
	padding: 0.75rem;
	margin-right: (@spacing/2);
}

.referal--empty,
.signal--empty {
	display: block;
	font-style: italic;
}

.referal--filter-traject,
select.signal--filter-traject {
	@media @breakpoint-two {
		display: none;
	}
}
