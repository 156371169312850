// ============ Groups
@media @breakpoint-one {
  .groups--panel li {
    > * {
      &.groups--name {
        flex-grow: 3;
      }

      &.groups--description {
        flex-grow: 5;
        padding-right: @spacing;
      }
    }
  }
}

.groups--panel {
  .groups--name i {
    padding: 0 0.5rem;
    float: right;
  }

  .groups--description {
    white-space: normal;
    overflow: visible;
    display: block;
  }
}
