.accessprofileattrvals--panel {
  ul {
    margin-bottom: 0;
  }

  li > * {
    &.accessprofileattrvals--date {
      flex-grow: 0;
      flex-basis: 16rem;
    }

    &.accessprofileattrvals--source {
      flex-grow: 0;
      flex-basis: 2rem;
      overflow: visible;
      position: relative;

      & > span.accessprofileattrvals--line {
        display: block;
        background-color: @color-neutral-30;
        width: 2px;
        position: absolute;
        top: (-@spacing/2);
        bottom: (-@spacing/2);
        left: 0.75rem;
      }
      & > span.accessprofileattrvals--indicator {
        display: block;
        background: white;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: @border-radius-1;
      }

      &.is-start-of-source > span.accessprofileattrvals--line {
        bottom: 0;
      }
      &.is-end-of-source > span.accessprofileattrvals--line {
        top: 0;
      }
      &.is-selected > span.accessprofileattrvals--line {
        background-color: @color-neutral-60;
      }
      &.is-empty > span.accessprofileattrvals--line {
        display: none;
      }

      &.is-end > span.accessprofileattrvals--indicator {
        border: 2px solid @color-neutral-30;
      }
      &.is-error > span.accessprofileattrvals--indicator {
        background-color: @color-alert;
      }
      &.is-value > span.accessprofileattrvals--indicator {
        background-color: @color-success;
      }
      &.is-seen > span.accessprofileattrvals--indicator,
      &.is-empty > span.accessprofileattrvals--indicator {
        display: none;
      }
    }

    &.accessprofileattrvals--value {
      flex-grow: 1;
      white-space: normal;
      padding-left: @spacing;

      &.is-error {
        color: @color-alert;
      }
    }
  }

  li.is-selected {
    font-weight: 800;
    background-color: @color-action-50;

    span.accessprofileattrvals--value.is-error {
      color: @color-alert-dark;
    }
  }

  .flextable--header .accessprofileattrvals--source {
    padding-top: 4rem;
    width: 2rem;

    .accessprofileattrvals--source-header {
      transform: rotate(-45deg);
      display: block;
    }

    &.is-selected {
      font-weight: 800;
      color: @color-neutral-60;
    }
  }
}

