// ============ Manage access

.access--panel {
  .vertical-filter--anchor {
    top: -12.5rem;
  }
}

.access--hidden-groups {
  border-top: 1px solid @color-neutral-10;
  margin-top: 2px;
}

.access--private-group {
  color: @color-neutral-40;
}
