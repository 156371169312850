// =========== launchpad

.launchpad--panel {
	.panel;

	a.launchpad--context-button {
		float: right;
		margin: (@spacing/2) 1.75rem (@spacing/2) (@spacing/2);
		line-height: 2.4rem;

		@media @before-breakpoint-one {
			display: none;
		}
	}

	h2 {
		font-size: 1.6rem;
		margin: (@spacing/2) 0;
		padding-left: @spacing;

		@media @before-breakpoint-one {
			font-size: 1.4rem;
			margin: (@spacing/3) 0;
			padding-left: (@spacing/3);
		}
	}

	ul {
		.mixin-display-flex;
		.mixin-flex-wrap(wrap);
	}

	.launchpad--tile {
		.mixin-flex(0 0 19rem);
		position: relative;

		@media @before-breakpoint-one {
			.mixin-flex(0 0 50%);
		}
	}

	.launchpad--link {
		display: block;
		padding: (@spacing/2) @spacing;
		cursor: pointer;
		border-radius: @border-radius-1;

		&:hover {
			.launchpad--tile-logo {
				border: 1px solid @color-brand-50;
			}
			.launchpad--edit {
				border-left: 1px solid @color-brand-50;
				border-bottom: 1px solid @color-brand-50;
			}
		}

		@media @before-breakpoint-one {
			padding: (@spacing/3);
		}
	}

	.launchpad--edit {
		position: absolute;
		right: @spacing;
		top: (@spacing/2);
		background-color: white;
		padding: 0 0 2px 0.5rem;
		border-left: 1px solid @color-neutral-10;
		border-bottom: 1px solid @color-neutral-10;
		border-radius: @border-radius-1;
		color: @color-brand-50;

		&:hover {
			color: @color-brand-70;
		}

		@media @before-breakpoint-one {
			right: (@spacing/3);
			top: (@spacing/3);
		}
	}

	.launchpad--tile-logo {
		border: 1px solid @color-neutral-10;
		border-radius: @border-radius-1;
	}

	.launchpad--tile-title {
		margin-bottom: 0;
		color: @color-neutral-60;

		@media @before-breakpoint-one {
			font-size: 1rem;
		}
	}
}

div.launchpad--panel {
	padding: 0;
}

div.launchpad--tile-logo {
	padding: (@spacing/2);

	img,
	svg {
		width: 100%;
		height: 7rem;
		object-fit: contain;

		@media @before-breakpoint-one {
			height: 6rem;
		}
	}
}

.form--fields div.launchpad--tile-logo * {
	object-position: left;
}

div.launchpad--logo-edit {
	position: relative;
	border: 1px solid @color-neutral-30;
	border-radius: @border-radius-1;
	box-shadow: inset 0 1px 2px @color-neutral-10;

	.launchpad--logo-edit-icon {
		position: absolute;
		right: -1px;
		top: -1px;
		background-color: white;
		padding: 0 0 0.13rem 0.5rem;
		border-left: 1px solid @color-neutral-30;
		border-bottom: 1px solid @color-neutral-30;
		border-radius: @border-radius-1;
		z-index: 5;
	}

	input[type='file'] {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}
}
