// ============ Audit

.audit--months {
  margin: 0.6rem 0;
  display: flex;
  text-align: center;

  div {
    flex-grow: 1;

    input {
      display: block;
      margin: auto;
    }
  }
}

.audit--warning--text {
  color: @color-warning;
}

.audit--alert--text {
  color: @color-alert;
}
