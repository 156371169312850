// =========== Base

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

*,
*:after,
*:before {
  .mixin-box-sizing(border-box);
}

::-moz-selection {
  background: @color-brand-70;
  color: @white;
  text-shadow: none;
}

::selection {
  background: @color-brand-70;
  color: @white;
  text-shadow: none;
}

html {
  color: @color-neutral-60;
  font: 400 @font-size @font-base;
  height: 100%;
  width: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  width: 100%;
  background: @color-neutral-10;
  line-height: 1.5em;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: -moz-transform 50ms ease-out 0s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.5em;
  margin: 0 0 0.75rem;
}

h1 {
  font-size: 2.25rem;
  font-weight: 300;
}

h2 {
  font-size: 1.2rem;

  &.is-header {
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: @font-size-20;
    color: @color-neutral-50;
    text-align: center;
    position: relative;
    -webkit-backface-visibility: hidden;
    word-wrap: break-word;

    span.is-soft {
      color: @color-neutral-40;
    }

    &.text-left {
      text-align: left;
    }
  }
}

h3 {
  font-size: 1.15rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 1.05rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-bottom: @spacing;

  &.lead {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

a {
  color: @color-brand-50;
  font-weight: 600;
  text-decoration: none;
  .mixin-transition(color 50ms);
  cursor: pointer;

  &:hover {
    color: @color-brand-70;
  }
}

a.referral {
  padding-left: 1rem;

  .mixin-transition(~"color 50ms, padding-left 50ms");

  &:hover {
    padding-left: 1.25rem;
  }

  &:before {
    position: relative;
    top: 0.15rem;
    margin-right: 0.5rem;
  }
}

small {
  font-size: @font-size-20;
  color: @color-neutral-40;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

code,
pre {
  font-family: 'courier new', monospace;
}

abbr {
  border-bottom: 1px dotted @color-neutral-40;
}

del {
  text-decoration: line-through;
}

mark {
  background-color: lighten(yellow, 35%);
}

hr {
  background-color: transparent;
  border: solid @color-neutral-30;
  border-width: 1px 0 0;
  clear: both;
  margin: 2em @spacing;

  &.is-wide {
    margin-left: 0;
    margin-right: 0;
  }
}

ol,
ul {
  margin: 0 0 @spacing 1.5em;
}

ul {
  list-style: none;
  margin-left: 0;
}

ol {
  list-style: decimal;
}

dl {
  margin-bottom: @spacing;

  dt {
    font-weight: bold;
  }

  dd {
    margin-left: @spacing;
  }

  &.advanced {
    .mixin-clearfix;

    dt {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      clear: left;
      color: @color-neutral-40;
      font-weight: normal;
      padding-top: 0.5rem;

      @media @breakpoint-three {
        padding-right: 0.5rem;
        text-align: left;
        word-wrap: break-word;
      }

      &:first-of-type {
        padding-top: 0;
      }
    }

    dd {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      margin-left: 0;

      @media @breakpoint-three {
        word-wrap: break-word;
      }

      &+dd {
        margin-top: 0.35rem;
      }
    }
  }

  &.is-horizontal {
    dd,
    dt {
      display: inline-block;
      width: 49%;

      &.is-wide {
        width: 100%;
      }
    }
  }
}

*[hidden], div[hidden] {
  display: none;
}
