// ========== Open sans

@font-face {
  font-family: 'Open sans';
  src: url("../fonts/OpenSans-Light-webfont.woff") format('woff'), url("../fonts/OpenSans-Light-webfont.ttf") format('truetype'), url("../fonts/OpenSans-Light-webfont.svg#open_sansbold") format('svg');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'Open sans';
  src: url("../fonts/OpenSans-Regular-webfont.woff") format('woff'), url("../fonts/OpenSans-Regular-webfont.ttf") format('truetype'), url("../fonts/OpenSans-Regular-webfont.svg#open_sansregular") format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open sans';
  src: url("../fonts/OpenSans-Semibold-webfont.woff") format('woff'), url("../fonts/OpenSans-Semibold-webfont.ttf") format('truetype'), url("../fonts/OpenSans-Semibold-webfont.svg#open_sansbold") format('svg');
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: 'Open sans';
  src: url("../fonts/OpenSans-Bold-webfont.woff") format('woff'), url("../fonts/OpenSans-Bold-webfont.ttf") format('truetype'), url("../fonts/OpenSans-Bold-webfont.svg#open_sansbold") format('svg');
  font-weight: 700;
  font-style: bolder;
}
