// =========== List

ul.checklist {
  list-style: none;
  margin-left: 0;

  li.checklist--item {
    .mixin-display-flex;
  }

  .checklist--title {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    &.checklist--collapsed {
      font-weight: 700;
    }
  }

  .checklist--description {
    margin-top: -0.75rem;
    margin-bottom: 0.75rem;

	small {
      display: block;
    }
  }

  .checklist--icon {
    min-width: 4rem;
    height: 3rem;

    i.icon {
      &.is-task-done {
        color: @color-brand-50;
      }

      &.is-task-error {
        color: @color-alert;
      }

      &.is-task-alert {
        color: @color-warning;
        padding: 0.3rem;
        display: inline-block;
        font-size: 2.4rem;
      }

      font-size: 3rem;
    }

    i.checklist--running {
      font-size: 1.2rem;
      line-height: 3rem;
      padding-left: 0.5rem;
    }
  }

  .is-skipped .checklist--icon i.icon.is-task-done {
    color: @color-neutral-30;
  }

  .checklist--abort {
    float: right;
  }

  .checklist--body {
    width: 100%;
  }

  .is-skipped .checklist--title {
    color: @color-neutral-40;
  }

  .is-error .checklist--title {
    color: @color-alert;
  }
}
