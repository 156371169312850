// =========== Navigation-items

.navigation-items {
  .mixin-flex(1 1 100%);
  .mixin-display-flex;
  .mixin-flex-direction(column);
  .mixin-align-items(stretch);
  padding: 0.5rem 0 0;
  position: relative;
  z-index: 9;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  @media @breakpoint-two {
    width: @navigation-width;
  }

  h2 {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
}

div.navigation-items--main {
  .mixin-display-flex;
  .mixin-flex(1 1);
  .mixin-flex-direction(column);

  position: relative;
  min-height: 2.8rem;
  max-height: 4rem;
  border-bottom: 1px solid @color-neutral-50;

  &:first-of-type {
    border-top: 1px solid @color-neutral-50;
  }

  > a {
    .mixin-display-flex;
    .mixin-align-items(center);
    .mixin-align-self(stretch);
    .mixin-flex(1 1);

    padding-left: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 300;
    color: @white;
    text-transform: uppercase;

    @media @breakpoint-three {
      font-size: @font-size-40;
    }
  }

  &.is-selected,
  &:hover:not(.is-wizard) {
    background-color: @color-brand-50;
  }

  &.is-wizard {
    &:first-of-type > span.navigation-items--line {
      top: 50%;
    }

    &:last-child > span.navigation-items--line {
      bottom: 50%;
    }

    &:first-of-type i.icon,
    &:last-of-type i.icon {
      height: 2px;
      border-radius: unset;
    }

    > span.navigation-items--line {
      display: block;
      position: absolute;
      border-right: 1px solid @color-neutral-10;
      top: 0;
      bottom: 0;
      left: 1.75rem;
    }

    > a {
      cursor: default;
      padding-left: 3.5rem;
      font-size: 1rem;
    }

    i.icon {
      position: absolute;
      left: 1rem;
	  top: auto;
      background: @white;
      border: 1px solid @color-neutral-10;
      border-radius: 100%;
      width: 1.6rem;
      height: 1.6rem;
	  line-height: 1.6rem;
      text-align: center;
    }

    &.is-done {
      i.icon {
        background: @color-brand-50;
      }
    }

    &.is-substep {
      background: @color-neutral-90;

      > a {
        text-transform: none;
      }

      i.icon {
        width: 0.6rem;
        height: 0.6rem;
        left: 1.5rem;
      }

      &.is-selected {
        background-color: @color-brand-50;
      }
    }
  }
}

div.navigation-items--sub {
  .mixin-display-flex;
  .mixin-flex(1 1);
  .mixin-flex-direction(column);

  min-height: 1.8rem;
  max-height: 3rem;
  background-color: @color-neutral-90;

  > a {
    .mixin-display-flex;
    .mixin-align-items(center);
    .mixin-align-self(stretch);
    .mixin-flex(1 1);

    padding-left: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 300;
    color: @color-neutral-30;

    @media @breakpoint-three {
      font-size: @font-size-40;
    }
  }

  &.is-selected,
  &:hover {
    background-color: @color-brand-50;
  }
}

div.navigation-items--separator {
  .mixin-display-flex;
  .mixin-flex(3 1);
  .mixin-align-items(flex-end);

  min-height: 5rem;
  max-height: 8rem;
  padding: 2rem 0.5rem 1rem 2rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
}
