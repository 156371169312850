// ============ Servers

.servers--title {
	.mixin-display-flex;
	.mixin-align-items(center);

	span {
		.mixin-flex(1 1 auto);
	}
}

.servers--wrapper {
	position: relative;

	>div:last-of-type {
		margin-bottom: (@spacing/2);
	}
}

h2.servers {
	.mixin-display-flex;
	.mixin-align-items(center);
	font-size: @font-size-50;
	margin-bottom: 0;
	word-wrap: word-break;
	color: @color-neutral-60;

	&.is-active span {
		background-color: @color-success;
	}

	span {
		border-radius: 50%;
		height: (@spacing/2);
		width: (@spacing/2);
		min-width: (@spacing/2);
		display: inline-block;
		background-color: @color-alert;
		margin-right: 0.5rem;
	}
}

&.servers--foldable {
	h2.servers {
		margin-bottom: .5rem;
	}
}

.servers--logout-time {
	white-space: nowrap;
	color: @color-brand-60;
	font-size: 1.5rem;
}

form.servers--time {
	border-top: 1px solid @color-neutral-20;
	padding-top: (@spacing/2);
	margin-top: (@spacing/2);

	select {
		.mixin-flex(1 1 auto);
		min-width: 0;
		margin-left: 0.35rem;
	}

	input[type="range"] {
		width: 100%;
		height: 3rem;
	}
}

.servers--range {
	position: relative;

	span {
		color: @color-neutral-40;
		font-size: @font-size-20;
		position: absolute;
		bottom: 0;
		z-index: 3;
		top: 0.5rem;
		width: 4rem;
		margin-left: -2rem;
		text-align: center;
	}

	div.ticks {
		position: absolute;
		height: 0.6rem;
		width: ~"calc(100% - 1rem)";
		margin-left: 0.5rem;
		z-index: 1;
		top: 1.6rem;

		.tick {
			width: 1px;
			height: 100%;
			position: absolute;
			border: 1px solid @color-neutral-30;

			&.small {
				height: 50%;
			}

			&.permission {
				border-color: transparent;
				border-bottom-color: @color-alert;
				border-width: 0 0.5rem 0.9rem;
			}
		}
	}
}

.servers--edit {
	top: 0.2rem;
}

.servers--edit,
.servers--extend {
	float: right;
	position: relative;
	cursor: pointer;
	line-height: 1.8rem;

	i {
		margin-left: 0.3rem;
	}
}

.servers--unfold {
	display: inline;

	&:hover[href]>small {
		color: @color-brand-70;
	}

	&:not([href])>small {
		cursor: default;
	}
}

.servers--config-required .servers--edit {
	color: @color-alert;
}
