// =========== Header

header.header {
	.mixin-flex(0 0 auto);
	-webkit-box-flex: 0;
	position: fixed;
	z-index: 50;
	left: 0;
	top: 0;
	right: 0;

	@media @breakpoint-two {
		left: @navigation-width;
	}

	> div:only-of-type {

		@media @breakpoint-two {
			position: relative;
		}
	}

	a.button {
		margin-right: 0.5rem;
	}
}
