// =========== Files

.files--wrapper {
	strong {
		display: block;
		margin-bottom: 0.3em;
	}

	> span {
		font-style: italic;
		color: @color-neutral-40;
	}
}

ul.files {
	.mixin-display-flex;
	.mixin-flex-wrap(wrap);
	margin: 0;
	list-style: none;
	-webkit-backface-visibility: hidden;
	// .mixin-columns(3, 10px);

	> li {
		.mixin-flex(0 0 auto);
		display: inline-block;
		max-width: 100%;
		margin: 0 0.30rem 0.35rem 0;
		// margin: 0 0 2px 0;
	}

	> li.files--add > span,
	> li.files--add a {
		padding: 0.3rem 0.5rem 0.15rem 1.75rem;
		background: none;
		color: @color-neutral-40;
		font-weight: 600;
		cursor: pointer;

		i.icon:before {
			top: 0.5rem;
		}
	}

	> li > span,
	> li > a {
		max-width: 100%;
		display: inline-block;
		position: relative;
		padding: 0.15rem 0.5rem 0.15rem 2rem;
		border-radius: @border-radius-2;
		background: @color-neutral-10;
		color: @color-neutral-60;
		font-weight: 400;
		cursor: initial;

		&:hover {
			color: @color-neutral-70;
		}

		&.has-no-icon {
			padding-left: 0.5rem;
		}
	}

	> li.has-dropdown > span,
	> li.has-dropdown > a {
		cursor: pointer;
	}

	> li > a > i.icon:before {
		position: absolute;
		top: 0.4rem;
		left: 0.55rem;
		display: block;
	}

	> li > a > i.icon {
		opacity: 0.5;
	}

	> li > a.is-active {
		background-color: @color-success;
		color: @white;
	}

	a > s,
	a > span,
	span > span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		display: block;
	}
}

li.files--none {
	margin-left: -4px;

	a {
		font-weight: 600;
		color: @color-alert;
	}

	span {
		padding-left: 0.15rem;
	}
}

.files--submitted {
	> li > a {
		background: @color-neutral-30;
	}
}

.files--upload {
	position: absolute;
	right: 100%;
	bottom: 0;
	width: 100%;
	height: 0.2rem;
	background-color: @color-brand-40;
	-webkit-transition: right 0.5s, opacity 1s;
	-moz-transition: right 0.5s, opacity 1s;
	transition: right 0.5s, opacity 1s;
}
