// =========== Filter

.filter {
  background-color: @color-neutral-10;
  font-weight: 600;
  text-align: left;
  font-size: @font-size-20;
  color: @color-neutral-50;
  padding: 0.35rem;
  position: relative;

  &.filter--filterbar {
    min-height: 4.1rem;
    padding: (@spacing/2);

    @media @breakpoint-two {
      padding: (@spacing/2) @spacing;
    }
  }

  &.filter--tablefilter {
    .mixin-display-flex;
    .mixin-align-items(flex-start);
	.mixin-flex-direction(column);
    @media @breakpoint-one {
      .mixin-align-items(center);
	  .mixin-flex-direction(row);
    }

    min-height: 2.2rem;
    padding: (@spacing/2) @spacing;

    .filter--sort {
      .mixin-flex(1 1 auto);
      padding-top: 0.5rem;

      @media @breakpoint-one {
        padding-top: 0;
      }
    }

    input[type=search] {
      .mixin-flex(0 0 auto);
      margin-right: @spacing;
    }
  }

  &.is-open {
    display: block;
  }

  & + table {
    margin-top: -1.5rem;
  }

  & + .content {
    padding-top: 0;
  }

  input[type=email],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=url],
  select {
    display: block;
    margin-bottom: 2px;
    padding: 0.15rem 0.5rem;

    @media @breakpoint-one {
      display: inline-block;
      width: auto;
      margin: 0 0.75rem 0 0;
      padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    }
    @media @breakpoint-three {
      min-width: 15.4rem;
    }

    &.is-wide {
      @media @breakpoint-four {
        min-width: 25rem;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  span.select2 {
    @media @before-breakpoint-one {
      margin-bottom: 2px;

      span.select2-selection {
        height: ~"calc(1.8rem + 2px)";

        span.select2-selection__rendered {
          line-height: 1.8rem;
        }

        span.select2-selection__rendered {
          padding-left: 0.5rem;
        }

        span.select2-selection__arrow {
          height: 1.8rem;
        }
      }
    }
  }

  input[type=text].hasDatepicker {
    min-width: 10rem;
  }

  a.button,
  button,
  input[type=submit] {
    width: 100%;
    padding: 0.15rem 0.5rem;
    border-width: 1px;
    margin: 0 1rem 0 0;
    position: relative;
    top: -1px;

    @media @breakpoint-one {
      padding: 0.5rem 0.75rem;
      width: auto;
    }
  }

  label {
    cursor: pointer;
    margin-right: 1rem;
  }
}

.filter.dashboard {
  background-image: none !important;
  background-color: @white !important;

  input {
    width: 100%;
  }
}

.filter--items {
  width: 100%;
  display: none;

  @media @breakpoint-one {
    position: relative;
    display: block;
  }

  > * {
    display: block;
    min-width: 100%;

    @media @breakpoint-one {
      min-width: initial;
      display: inline-block;
    }
  }
}

.filter--toggle {
  display: block;
  text-align: center;

  @media @breakpoint-one {
    display: none;
  }

  a {
    color: @color-neutral-50;
    display: inline-block;
  }

  i.icon {
    position: relative;
    top: 2px;
    font-size: 1rem;
    margin-right: 0.5rem;
  }
}

.filter.is-open {
  .filter--items {
    display: block;
  }
}

.filter--open-filter {
  @media @breakpoint-two {
    display: none;
  }
}

.filter--sort {
  text-align: center;

  a {
    margin: 0 (@spacing/3);
    padding: 0 (@spacing/6);
    max-width: 12rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;

    &.is-selected {
      background-color: @color-brand-50;
      color: @color-neutral-10;
      border-radius: @border-radius-1;
    }
  }
}
