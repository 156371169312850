// =========== Content

section.content-wrapper {
  .mixin-flex(1 1 100%);
  position: relative;
  transition: margin-top 1s;
  -webkit-transition: margin-top 1s;
}

div.content {
  .mixin-clearfix;
  padding: (@spacing/2) 0;

  @media @breakpoint-two {
    padding: @spacing;
  }

  &.has-no-padding {
    padding: 0;
  }
}

.has-filterbar-header .content {
  padding-top: 0;
}

.content--part {
  padding: (@spacing / 2) 0;
}

div.content--buttons {
  position: relative;
  clear: both;

  small {
    color: @color-neutral-50;
    display: block;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
    @media @breakpoint-one {
      display: inline-block;
      padding-top: 0.5rem;
      float: left;
    }
  }

  .button,
  .button-action,
  .button-detail,
  .button-soft,
  button,
  input[type=submit] {
    text-align: center;
    margin-bottom: 0.25rem;
    display: inline-block;

    &.right {
      margin-left: 0.25rem;
    }

    &.left {
      margin-right: 0.25rem;
    }
  }
}

a#wicketDebugLink {
  bottom: 100px!important;
}

img.profile--storebadge {
    width: 12rem;
    height: 4rem;
}
