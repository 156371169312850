// =========== Header-toolbar

.header.is-maintenance .header-toolbar {
  background: @color-alert;
}

.header-toolbar {
  display: flex;
  background: @color-neutral-70;
  color: @white;
  height: 4.1rem;
  font-weight: 600;

  .header-toolbar--title {
    display: inline-flex;
    justify-content: flex-end;
    overflow: hidden;
  }

  h1 {
    text-align: center;
    font-weight: 300;
    margin: 0 0 0 2.5rem;
    line-height: 4.1rem;
    font-size: @font-size-30;
    padding: 0 0.5rem 0 0;
    text-transform: uppercase;
    white-space: nowrap;

    @media @breakpoint-one {
      padding: 0 0.5rem 0 0;
      font-size: @font-size-40;
    }

    @media @breakpoint-two {
      margin: 0;
      text-align: left;
      padding-left: (@spacing / 2);
    }

    span {
      white-space: nowrap;

      @media @breakpoint-two {
        max-width: auto;
      }
    }

    span.is-fixed {
      display: none;

      + span.is-dynamic {
        margin-left: 4px;
      }

      @media @breakpoint-two {
        display: inline;
      }
    }
  }

  h1 > i.icon {
    position: relative;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    display: none;

    &:last-of-type {
      display: inline;
    }

    &:only-of-type {
      display: none;

      @media @breakpoint-two {
        display: inline;
      }
    }

    @media @breakpoint-two {
      display: inline;
    }
  }

  h1 > a {
    color: fade(@white, 70%);
    font-weight: 300;

    &:hover {
      color: @white;
    }

    &:only-of-type span {
      display: inline;
    }

    &:first-of-type {
      span {
        display: none;
      }

      + i {
        display: none;
      }

      @media @breakpoint-two {
        span {
          display: inline;
        }

        + i {
          display: inline;
        }
      }
    }

  }

  .header-toolbar--open-navigation {
    cursor: pointer;
    padding: 1rem 1rem 1rem 0.8rem;
    line-height: 2.3;

    @media @breakpoint-two {
      display: none;
    }
  }

  .header-toolbar--action {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    cursor: pointer;
    line-height: 3.1rem;
    padding-right: (@spacing/2);
    padding-left: 0.4rem;
    color: @white;

    &:hover {
      color: fade(@white, 70%);
    }

    i.icon {
      margin-left: 0.5rem;
      position: relative;
      top: 0.05rem;
    }

    span {
      display: none;

      @media @breakpoint-two {
        display: inline-block;
        min-width: 9rem;
        text-align: right;
      }
    }
  }
}

.header-toolbar--buttons {
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: (@spacing / 2);

  select {
    margin-left: 1.5rem;
  }
}

.header-toolbar--button {
  flex: 0 0 auto;
  color: @white;
  border: 2px solid @color-brand-30;
  border-radius: @border-radius-1;
  cursor: pointer;
  font-size: @font-size-20;
  font-weight: bold;
  padding: 0.35rem (@spacing/2);
  text-align: center;
  margin-left: @spacing;

  &:hover {
    color: fade(@white, 80%);
  }
}

a.header-toolbar--button {
  i.icon {
    transform: translateY(0.1rem);
    display: inline-block;
    margin-right: 0.3rem;
  }

  span.has-icon {
    display: none;
    @media @breakpoint-one {
      display: inline;
    }
  }

  &.is-alert {
    border-color: @color-action-50;
    color: @color-action-50;
  }
}

.header-toolbar--icon-button {
  .header-toolbar--button;

  margin-left: -2px;
  border-left: none;
  padding: 0.5rem 0.5rem 0.2rem;
}
