// =========== Vertical-filter

div.vertical-filter {
  display: none;
  position: fixed;
  max-height: ~"calc(100% - 7.7rem)";
  height: 100%;
  overflow-y: auto;
  min-width: 15rem;
  padding-top: @spacing;

  @media @breakpoint-two {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0 0 @spacing;
  }

  li.active {
    &:before {
      background: @color-neutral-40;
    }

    a {
      color: @color-neutral-70;
      background: @color-neutral-30;
    }
  }

  li {
    position: relative;
    padding-left: 4px;
    margin-bottom: 2px;
  }

  li:before {
    width: 3px;
    background: @color-neutral-30;
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  a {
    text-transform: uppercase;
    color: @color-neutral-40;
    font-size: @font-size-20;
    font-weight: 600;
    display: block;
    padding: 0.35rem 0.75rem;
    max-width: 300px;

    &:hover {
      color: @color-neutral-60;
    }
  }

  i {
    position: relative;
    top: 2px;
    margin-right: 2px;
  }
}

.vertical-filter--anchor {
  position: relative;
  top: -7.7rem;
  width: 1px;
  height: 0;
  display: block;
}
