ul.wizard {
	.mixin-display-flex;
	.mixin-flex-direction(row);
	.mixin-flex-wrap(nowrap);

	li {
		.mixin-flex(1 1 auto);

		list-style: none;
		display: block;
		text-align: center;
		position: relative;
		padding: 0;
		margin: 0;
		width: 100%;
		white-space: nowrap;

		.wizard--bubble {
			display: block;
			border-radius: 5px;
			width: 5rem;
			height: 5rem;
			line-height: 5rem;
			margin: 0 auto 0.5rem;
			background-color: white;
			font-size: 300%;
			font-weight: 600;
			color: @color-brand-50;
			border: 2px solid @color-brand-50;

			&.is-completed {
				background-color: @color-brand-50;
				border-color: @color-brand-50;
				color: @color-neutral-10;
			}

			&:after,
			&:before {
				position: absolute;
				top: ~"calc(2.5rem - 3px)";
				height: 5px;
				width: 100%;
				content: '';
				background-color: @color-brand-50;
				z-index: -1;
			}

			&:before {
				left: 0;
			}

			&:after {
				right: 0;
			}
		}
	}
}
