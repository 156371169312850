div.progress {
  border: 1px solid @color-neutral-30;
  border-radius: @border-radius-1;
  box-shadow: inset 0 1px 2px @color-neutral-10;
  color: white;
  font-family: @font-base;
  font-size: @font-size-30;
  line-height: 1.5em;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  width: 100%;
  min-width: 6rem;
  background-color: white;

  > .progress--value {
    padding: 0.5rem 0;
    overflow: visible;
    background: @color-brand-50;
    text-align: center;
    border-radius: @border-radius-1;
  }
}

.dot-typing {
  position: relative;
  left: -9984px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: @color-brand-50;
  color: @color-brand-50;
  box-shadow: 9984px 0 0 0 @color-brand-50, 9999px 0 0 0 @color-brand-50, 10014px 0 0 0 @color-brand-50;
  animation: dotTyping 1.5s infinite linear;
  display: inline-block;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 @color-brand-50, 9999px 0 0 0 @color-brand-50, 10014px 0 0 0 @color-brand-50;
  }
  11% {
    box-shadow: 9984px -10px 0 0 @color-brand-50, 9999px 0 0 0 @color-brand-50, 10014px 0 0 0 @color-brand-50;
  }
  22% {
    box-shadow: 9984px 0 0 0 @color-brand-50, 9999px 0 0 0 @color-brand-50, 10014px 0 0 0 @color-brand-50;
  }
  33% {
    box-shadow: 9984px 0 0 0 @color-brand-50, 9999px -10px 0 0 @color-brand-50, 10014px 0 0 0 @color-brand-50;
  }
  44% {
    box-shadow: 9984px 0 0 0 @color-brand-50, 9999px 0 0 0 @color-brand-50, 10014px 0 0 0 @color-brand-50;
  }
  55% {
    box-shadow: 9984px 0 0 0 @color-brand-50, 9999px 0 0 0 @color-brand-50, 10014px -10px 0 0 @color-brand-50;
  }
  66% {
    box-shadow: 9984px 0 0 0 @color-brand-50, 9999px 0 0 0 @color-brand-50, 10014px 0 0 0 @color-brand-50;
  }
}
