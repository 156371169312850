// =========== Form-editor

#form-editor {
	position: relative;

	ul {
		display: inline-block;
		list-style: none;
		margin: 0;
		background: @color-neutral-10;
		width: 100%;
	}

	li {
		position: relative;
		display: inline-block;
		width: 2rem;
		text-align: center;
		font-weight: 600;
		font-size: @font-size-30;
		cursor: pointer;
		margin: 0;
		padding: 0.2rem 0;

		i:before {
			font-size: @font-size-20;
		}
	}

	li.wysihtml5-command-dialog-opened {
		background: @color-neutral-30;
	}

	li.wysihtml5-action-active,
	li.wysihtml5-command-active {
		background: @color-neutral-30;
	}
}

#form-editor.wysihtml5-commands-disabled [data-wysihtml5-command] {
	opacity: 0.4;
	cursor: default;
	background-image: none !important;
}

[data-wysihtml5-dialog] {
	.mixin-display-flex;
	top: 45px;
	text-align: left;
	background: @color-neutral-30;
	padding: 0.4rem;

	a.button {
		.mixin-flex(0 1 auto);
		display: inline-block;
		padding: 0.1rem 0.4rem;
		border-width: 1px;
		position: relative;
		border-radius: 0;
	}

	input {
		.mixin-flex(0 1 auto);
		display: inline-block;
		padding: 0.1rem;
		border-radius: 0;
		border: 1px solid @white !important;
		box-shadow: none;
	}
}

[data-wysihtml5-dialog-action] {
	color: @white;
	cursor: pointer;
}

#form-editor--textarea,
iframe.form-editor--iframe {
	min-height: 20rem;
	padding: 0 0 0.5rem !important;
}
