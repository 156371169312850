.accessprofileattrs--panel li {
  > * {
    &.accessprofileattrs--attr {
      color: @color-neutral-40;
      padding-left: 3rem;
      flex-grow: 4;
    }

    &.accessprofileattrs--status {
      flex-grow: 2;
    }

    &.accessprofileattrs--value {
      flex-grow: 4;
	  white-space: normal;
    }
  }
}
