// =========== Tasks

// Tasks overview
div.tasks {
  //hetzelfde als .projectgroup, .log--wrapper
  list-style: none;
  margin: 0 0 @spacing;

  .sortable-placeholder {
    border: 2px dashed @color-neutral-30;
    background: transparent;
    height: 3em;
    margin-bottom: 2px;
  }
}
