// =========== ui-datepicker

.ui-datepicker {
  display: none;
  padding: 0.75rem 1rem;
  background: @white;
  box-shadow: 0 0 8px fade(@color-neutral-70, 30%);
  font: @font-size-40;
  text-align: center;
  z-index: 200!important;

  a {
    color: @color-neutral-50;
  }
}

.ui-datepicker-header {
  position: relative;
  margin: -.75rem -1rem 0.75rem;
  padding: 0.45rem;
  border-bottom: 1px solid @color-neutral-10;
  line-height: 1.9rem;
}

.ui-datepicker-next,
.ui-datepicker-prev {
  .mixin-material-icon;
  position: absolute;
  top: 0;
  display: block;
  width: 4rem;
  height: 4rem;
  line-height: 3rem;
  text-decoration: none;
  cursor: pointer;

  &:before {
    vertical-align: middle;
  }
  
  span.ui-icon {
	display: none;
  }
}

.ui-datepicker-prev {
  left: 0;

  // MARKER-ICON-DEP
  &:before {
    content: "arrow_left";
  }
}

.ui-datepicker-next {
  right: 0;

  // MARKER-ICON-DEP
  &:before {
    content: "arrow_right";
  }
}

.ui-datepicker-calendar {
  border-collapse: collapse;
  font-size: @font-size-20;
  line-height: 1.9rem;

  th {
    color: @color-neutral-50;
  }

  a,
  span {
    display: block;
    width: 31px;
    margin: auto;
    text-decoration: none;
    color: @color-neutral-60;
    border: 2px solid @white;
  }

  span {
    color: @color-neutral-40;
  }
}

.ui-datepicker-calendar .ui-state-hover {
  background: @color-neutral-10;
  color: @color-neutral-60;
}

.ui-datepicker-calendar .ui-state-active {
  background: @color-neutral-60;
  color: @white;
  cursor: default;
}

.ui-datepicker-today a {
  background: @color-brand-50;
  color: @white;

}

.ui-datepicker-inline {
  border: 1px solid @color-neutral-30;
  box-shadow: none;
  border-radius: @border-radius-1;

  .ui-datepicker-calendar {
    width: 100%;
  }
}

.ui-datepicker-other-month {
  //is dit nodig? 16 juli 2014
  color: @color-neutral-30;
}
