// =========== Tables

table.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  &.tblClick tr {
    cursor: pointer;
  }

  &.table--flex-columns {
    td {
      white-space: nowrap;

      &.table--flex-column {
        white-space: normal;
        overflow-wrap: anywhere;
      }
    }
  }

  th {
    background: @color-neutral-30;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    font-size: @font-size-20;
    color: @color-neutral-50;
    padding: 0.3rem 1rem 0.35em;
    white-space: nowrap;
  }

  td {
    border-bottom: 1px solid @color-neutral-30;
    padding: 0.5rem 1rem;
    vertical-align: middle;
  }

  a {
    color: @color-neutral-60;
    font-weight: normal;
  }

  .has-notice {
    position: relative;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -0.3rem;
      height: 0.6rem;
      width: 0.6rem;
      background: #f85e53;
      border-radius: 50%;
    }
  }
}

th.table--signal {
  width: 1rem;
}

td.table--avatar {
  width: 1px;
  height: 4rem;

  div {
    position: relative;
    padding-left: 4rem;
    white-space: nowrap;
  }

  span {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1.5rem;
    background-image: url("../media/user.png");
    background-size: cover;
    background-position: 50% 50%;
    height: 3rem;
    width: 3rem;
    display: block;
    border-radius: @border-radius-1;
    box-shadow: inset 0 0 4px 0 fade(#000, 25%);
  }

  a {
    color: @color-neutral-60;

    &:hover {
      color: @color-neutral-70;
    }
  }

  small {
    font-weight: normal;
    color: @color-neutral-40;
    display: block;
  }
}

table.table td.table--marker {
  width: 2rem;
  padding-right: 0;
}

th.table--sortable {
  cursor: pointer;

  i {
    position: relative;
    top: 0.25rem;
    vertical-align: middle;
  }

  &:hover {
    color: @color-neutral-70;
  }

  &.is-sorted {
    color: @color-brand-50;

    &:hover {
      color: @color-brand-70;
    }
  }
}

//Fixes beyonder bug
tbody+tbody {
  border-bottom: 0;
}
