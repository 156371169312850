// ============ Manage access
@media @breakpoint-one {
  .flextable--open-handler,
  .flextable--vert-label {
    display: none;
  }

  .flextable--narrow-label {
    display: inline;
  }

  .flextable--wide-label {
    display: none;
  }
  
  .flextable--iconwide-label {
    display: none;
  }
    
  .flextable--icon-label {
    display: inline;
  }
  
  .flextable--panel {
    @media @breakpoint-three {
      padding: 1rem 1.75rem;
    }

    h2 {
      padding-left: @spacing;

      & + small {
        padding-left: @spacing;

        @media @breakpoint-three {
          margin-bottom: @spacing;
        }
      }
    }

    div.flextable--item,
    li {
      padding: (@spacing/2) 0;

      > * {
        .mixin-flex(3 1 0px);

        &.flextable--name {
          padding-left: @spacing;
        }

        &.flextable--w1 {
          flex-grow: 1;
        }

        &.flextable--w2 {
          flex-grow: 2;
        }

        &.flextable--w3 {
          flex-grow: 3;
        }

        &.flextable--w4 {
          flex-grow: 4;
        }

        &.flextable--w5 {
          flex-grow: 5;
        }

        &.flextable--w6 {
          flex-grow: 6;
        }

        &.flextable--w7 {
          flex-grow: 7;
        }

        &.flextable--w8 {
          flex-grow: 8;
        }

        &.flextable--w9 {
          flex-grow: 9;
        }

        &.flextable--w10 {
          flex-grow: 10;
        }

        &.flextable--edit {
          display: none;
        }

        &.flextable--icon {
          flex-grow: 0;
          flex-basis: 6rem;
          text-align: center;

          a {
            padding: 0 0.35rem;
          }
        }
      }
    }
  }
}

@media @breakpoint-three {
  .flextable--narrow-label {
    display: none;
  }

  .flextable--wide-label {
    display: inline;
  }
}

.flextable--vert-label {
  font-weight: lighter;
}

.flextable--na {
  color: @color-neutral-40;
}

div.flextable--panel,
ul.flextable--panel {
  padding: 0;
}

.flextable--panel {
  .panel;

  a.flextable--context-button {
    float: right;
    margin: (@spacing/2) 1.75rem 0 (@spacing/2);
    line-height: 2.4rem;

    @media @before-breakpoint-one {
      display: none;
    }
  }

  a.flextable--inline-button {
    float: right;
    margin: 0 1.75rem 0 (@spacing/2);
  }

  h2 {
    font-size: 1.6rem;
    margin: (@spacing/2) 0;
	
	&:last-child {
		margin-bottom: (@spacing/2);
	}

    & + small {
      display: block;
      margin-top: (-@spacing/2);
      margin-bottom: (@spacing/2);
    }
  }

  div.flextable--item,
  li {
    .mixin-display-flex;
    border-bottom: 1px solid @color-neutral-10;

    &:last-child {
      border-bottom: none;
    }

    &.flextable--header {
      color: @color-neutral-40;
    }

    > * {
      padding-right: 0.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.flextable--name {
        white-space: normal;
        position: relative;
        overflow: visible;
      }

      &.flextable--no-data {
        flex-grow: 1;
        text-align: center;
      }
    }
  }
}

.flextable--header .flextable--sortable {
  cursor: pointer;

  &:hover {
    color: @color-neutral-60;
  }
}

@media @before-breakpoint-one {
  .flextable--narrow-label {
    display: none;
  }

  .flextable--icon-label {
    display: none;
  }

  .flextable--panel {
    h2 {
      padding-left: (@spacing/2);

      & + small {
        padding-left: (@spacing/2);
      }
    }
	
    a.flextable--context-button {
      display: block;
    }

    .flextable--header,
    div.flextable--item.flextable--header {
      display: none;
    }

    div.flextable--item,
    li {
      .mixin-flex-direction(column);
      padding: (@spacing/2) 0 (@spacing/2) (@spacing/2);
      overflow: hidden;
      line-height: 2rem;

      &.is-open > * {
        display: block;
        padding-left: (@spacing/2);

        &.flextable--name {
          padding-left: 0;
          padding-bottom: 0.5rem;
        }

        &:nth-child(n+2) {
          background-color: @color-brand-13;
        }
      }

      > * {
        .mixin-flex(1 1 auto);
        display: none;

        &.flextable--na,
        &:empty {
          display: none;
        }

        &.flextable--name {
          display: block;
          padding-right: (@spacing/2) + @spacing;
        }
      }
    }
  }

  div.flextable--item.is-open,
  li.is-open {
    .flextable--open-handler {
      background-image: url("../images/arrow-up.png");
    }
  }

  .flextable--open-handler {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    background-image: url("../images/arrow-down.png");
    background-size: 1rem;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position-y: (@spacing/2);
    background-position-x: right;
    padding-right: 1.25rem;
  }
}
