// =========== Tabs

ul.tabs {
  background: @color-neutral-30;
  border-radius: @border-radius-1;
  margin: 0 0 @spacing;
  padding: 0.35rem 0.35rem 0;

  li {
    display: inline-block;
    position: relative;
  }

  a {
    color: @color-neutral-40;
    display: block;
    padding: 0.75rem;

    &:hover {
      color: @color-neutral-70;
    }
  }

  li.is-selected a {
    background: @white;
    border-radius: 0.2rem 0.2rem 0 0;
    color: @color-neutral-70;
    font-weight: bold;
  }
}
