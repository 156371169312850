// =========== Forms

input[type=search] {
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input::-ms-clear {
  display: none;
}

input::-webkit-input-placeholder {
  font-weight: 400;
  color: @color-neutral-40;
}

input::-moz-placeholder {
  font-weight: 400;
  color: @color-neutral-40;
}

input:-ms-input-placeholder {
  font-weight: 400!important;
  color: @color-neutral-40!important;
}

input:input-placeholder {
  font-weight: 400;
  color: @color-neutral-40;
}

textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: @color-neutral-40;
}

textarea::-moz-placeholder {
  font-weight: 400;
  color: @color-neutral-40;
}

textarea:-ms-input-placeholder {
  font-weight: 400!important;
  color: @color-neutral-40!important;
}

textarea:input-placeholder {
  font-weight: 400;
  color: @color-neutral-40;
}

select[disabled] {
  font-weight: 400;
  color: @color-neutral-40;
}

input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid @color-neutral-30;
  border-radius: @border-radius-1;
  box-shadow: inset 0 1px 2px @color-neutral-20;
  color: @color-neutral-60;
  display: block;
  font-family: @font-base;
  font-size: @font-size-30;
  line-height: 1.5em;
  margin: 0;
  padding: 0.5em;
  vertical-align: baseline;
  width: 100%;
  min-width: 6rem;
  background-color: white;

  &:focus {
    border-color: @color-neutral-40;
    color: @color-neutral-70;
    outline: 0;
  }

  &:disabled {
    box-shadow: none;
    background: @color-neutral-10;
    color: @color-neutral-40;
  }
}

select {
  background-image: url("../images/arrow-down.png");
  background-size: 1rem;
  background-origin: padding-box;
  background-repeat: no-repeat;
  background-color: white;
  background-position: right 0.5rem center;
  padding-right: 2rem!important;

  &:active {
    background-image: url("../images/arrow-up.png");
  }
}

div.filter.filter--filterbar select.form--multiselect {
  display: none;
}

span.select2 {
  vertical-align: bottom;

  @media @breakpoint-one {
    margin: 0 0.75rem 0 0;
  }

  span.select2-selection {
    height: ~"calc(2.5rem + 2px)";
    border: 1px solid @color-neutral-30;
    border-radius: @border-radius-1;
    box-shadow: inset 0 1px 2px @color-neutral-20;
    color: @color-neutral-60;
    font-size: 1rem;
    font-weight: normal;

    &:focus {
      border-color: @color-neutral-40;
      color: @color-neutral-70;
      outline: 0;
    }

    span.select2-selection__rendered {
      padding-left: 0.75rem;
      padding-right: 2rem;
      line-height: 2.5rem;
    }

    button.select2-selection__clear {
      font-size: 1.5rem;
      color: black;
      display: block;
      height: 2.5rem;
      width: 1.5rem;
      margin: 0 2rem 0 0;
      padding: 0;
    }

    span.select2-selection__arrow {
      width: 2rem;
      height: 2.5rem;
      background-image: url("../images/arrow-down.png");
      background-size: 1rem;
      background-origin: padding-box;
      background-repeat: no-repeat;
      background-position: right 0.5rem center;

      b {
        display: none;
      }
    }
  }

  &.select2-container--open span.select2-selection span.select2-selection__arrow {
    background-image: url("../images/arrow-up.png");
  }
}

.select2-results__options {
	line-break: anywhere;
}

input[readonly],
textarea[readonly] {
  background-color: @color-neutral-10;
}

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top;
  display: block;
  width: 100%;
}

form {}

fieldset {
  > h2 {
    margin-left: (@spacing/2);
    @media @breakpoint-two {
      margin-left: 0;
    }
  }
}

legend {
  border: 0;
  display: block;
  margin-bottom: 0.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  font-size: @font-size-50;
}

ul.form {
  clear: both;
  padding: 0 !important;
  list-style: none;
  margin: 0 0 @spacing;

  > li {
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid @color-neutral-20;
    .mixin-clearfix;

    @media @breakpoint-two {
      padding: 1rem 1.5rem;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  > li > label {
    clear: left;
    display: block;
    font-weight: 400;
    padding-top: 0.6rem;
    text-align: left;
    color: @color-neutral-40;
    overflow: hidden;

    @media @breakpoint-one {
      .mixin-grid(16, 0, 1);
    }
  }

  div.form--fields {
    position: relative;

    @media @breakpoint-one {
      .mixin-grid(32, 0, 0);
    }

    &:first-child {
      width: 100%;
    }

    p,
    small {
      display: block;
      margin: 0.25rem 0;
    }

    p.form--description {
      margin: 0.6rem 0;

      a.button-soft {
        margin-top: ~"calc(-0.35rem - 2px)"
      }
    }

    pre.form--description,
    span.form--description {
      display: inline-block;
      margin: 0.6rem 0;
      width: 100%;
      min-height: 1.5rem;
    }

    > i.icon {
      position: absolute;
      top: 0.9rem;
      left: 0.65rem;
      color: @color-neutral-30;
    }

    input:focus ~ i {
      color: @color-neutral-50;
    }

    select ~ i.form--arrows {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 8px;
      height: 11px;
      background: @white;
      pointer-events: none;

      &:after,
      &:before {
        content: '';
        position: absolute;
        right: 0;
        border-right: 0.3rem solid transparent;
        border-left: 0.3rem solid transparent;
      }

      &:after {
        bottom: 0;
        border-top: 0.3rem solid @color-neutral-30;
      }

      &:before {
        top: 0;
        border-bottom: 0.3rem solid @color-neutral-30;
      }
    }

    table.table td {
      padding-left: 0;
      border-bottom: 1px solid @color-neutral-20;
    }
    
    table.table th {
      color: @color-neutral-50;
      padding-top: 2rem;
      padding-left: 0;
      background-color: white;
      border-bottom: 1px solid @color-neutral-50;
    }

    table.table tr:first-child * {
      padding-top: 0;
    }

    table.table tr:last-child * {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  label > input[type='checkbox'] {
    margin-right: 1rem;
  }
}

.form--checklist {
  list-style: none;
  margin: 0.6rem 0;

  li {
    margin: 0.5rem 0;
  }

  li.form--checklist-inline {
    display: inline-block;
    margin-right: 1.2rem;
  }

  input {
    display: inline;
    margin-right: 0.8rem;
  }

  label {
    .mixin-grid-reset;
    font-weight: normal;

    &:hover {
      color: @color-neutral-60;
      cursor: pointer;
    }
  }
}

ul.form--checklist-inline {
  .form--checklist;

  li {
    display: inline-block;
    margin: 0;
  }

  li ~ li {
    margin-left: 1.2rem;
  }
}

li.form--is-required {
  label:after {
    content: '*';
    color: @color-alert;
    margin-left: 0.25rem;
    font-size: @font-size-10;
    position: relative;
    top: -4px;
  }
}

div.form--error,
li.form--error {
  label {
    color: @color-alert !important;
  }

  input[type=email],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=url],
  select,
  textarea {
    border-color: @color-alert;
    -webkit-box-shadow: inset 0 0 0 1px @color-alert;
    box-shadow: inset 0 0 0 1px @color-alert;

    &:focus {
      border-color: @color-alert;
      -webkit-box-shadow: inset 0 0 0 1px @color-alert;
      box-shadow: inset 0 0 0 1px @color-alert;
    }
  }
  color: @color-alert;
  border: none;
}

ul.form--errors {
  list-style: none;
  margin: 0;

  li {
    font-size: @font-size-20;
  }
}

.form--large-errors ul.form--errors li {
  font-size: @font-size-30;
}

div.form--buttons {
  padding: 0.75rem;

  @media @breakpoint-two {
    padding: 1.5rem;
  }
}

div.form--fields div.form--field-with-button {
  .mixin-display-flex;
  .mixin-align-items(stretch);

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  a {
    margin: 0;
    padding-top: 0.6rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

span.form--fields-avatar {
  display: block;
  height: 5rem;
  width: 5rem;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: @border-radius-1;
  box-shadow: inset 0 0 4px 0 fade(#000, 25%);
}

div.form--upload {
  padding-top: 0.15rem;

  input {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    height: 3rem;
    width: 100%;
    top: 0;
    opacity: 0;
  }
}

div.form--uploads {
  padding-top: (@spacing / 2);

  p {
    margin-bottom: (@spacing / 4);
  }

  i {
    margin-left: (@spacing / 4);
    vertical-align: middle;
    color: @color-neutral-40;
  }
}

.form--upload {
  position: relative;
  overflow: hidden;

  i.icon {
    position: absolute;
    top: 0.9rem;
    left: 0.65rem;
    color: @color-neutral-30;
  }

  input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    width: 100% !important;
    background: @white;
    border: 1px solid @color-neutral-30;
    border-radius: @border-radius-1;
    box-shadow: inset 0 1px 2px @color-neutral-20;
    color: @color-neutral-70;
    cursor: pointer;
  }

  a {
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }

    &.form--upload-download {
      display: inline;
    }

    &.form--upload-remove {
      position: absolute;
      top: 0;
      width: 2rem;
      height: 3rem;
      right: 0.75rem;
    }

    &.form--upload-fetch {
      display: block;
      padding: 0.5rem 0.5rem 0.5rem 0;
      position: relative;
    }
  }
}

.form--upload-private-key {
  .mixin-display-flex;

  > .form--upload {
    .mixin-flex(1 1 auto);
  }

  > input[type='password'] {
    max-width: 16rem;
    margin: 0.4rem 0 0.4rem 0.75rem;
  }
}

.form--scripts {
  .form--script {
    margin-bottom: 1rem;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }

    > a {
      position: absolute;
      top: 5px;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      right: 5px;
      padding-left: 3px;

      &.has-select {
        right: 2.5rem;
      }
    }
  }

  textarea {
    font-family: 'courier new', monospace;
  }

  .form--addscript {
    display: inline-block;
    margin: 0.6rem 0;
  }
}

span.ui-helper-hidden-accessible[role=status] {
  display: none;
}

ul.ui-autocomplete.ui-menu {
  z-index: 200;
  position: absolute;
  background: @white;
  .mixin-transform(translateX(0.5rem));
  border: 2px solid @color-neutral-30;
  border-top: none;

  li {
    border-top: 1px solid @color-neutral-20;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;

    a.ui-state-focus {
      color: @color-action-70;
    }
  }
}

button.button-soft.ui-multiselect {
  background-color: white;
}

div.ui-multiselect-menu {
  background: @white;
  border: 2px solid @color-neutral-30;
  border-top: none;

  ul.ui-multiselect-checkboxes {
    border-top: 1px solid @color-neutral-20;
    margin: 0;
  }
}

// INPUT SLIDER
/* Styling base */
input[type=range] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  margin: 0;
  position: relative;
  z-index: 2;
  background: transparent;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  /* Hides the slider so custom styles can be added */
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: (@spacing/1.5);
  width: (@spacing/1.5);
  border-radius: 50%;
  .mixin-gradient(top, @color-brand-50, @color-brand-60);
  border: 2px solid @color-brand-60;
  cursor: pointer;
  margin-top: -5px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px fade(@color-neutral-50, 20%), 0 0 1px fade(@color-neutral-60, 20%);
  /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  height: (@spacing/1.5);
  width: (@spacing/1.5);
  border-radius: 50%;
  .mixin-gradient(top, @color-brand-50, @color-brand-60);
  border: 2px solid @color-brand-60;
  box-shadow: 1px 1px 1px fade(@color-brand-50, 20%), 0 0 1px fade(@color-neutral-60, 20%);
  /* Add cool effects to your sliders! */
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  height: (@spacing/1.5);
  width: (@spacing/1.5);
  border-radius: 50%;
  .mixin-gradient(top, @color-brand-50, @color-brand-60);
  border: 2px solid @color-brand-60;
  box-shadow: 1px 1px 1px fade(@color-neutral-50, 20%), 0 0 1px fade(@color-neutral-60, 20%);
  /* Add cool effects to your sliders! */
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.35rem;
  cursor: pointer;
  //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: @color-neutral-30;
  border-radius: @border-radius-1;
  border: 0.2px solid #010101;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: @color-neutral-40;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 0.35rem;
  cursor: pointer;
  //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: @color-neutral-30;
  border-radius: @border-radius-1;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 0.35rem;
  cursor: pointer;
  background: @color-neutral-30;
  border-radius: @border-radius-1;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-fill-lower {
  background: @color-neutral-30;
  border-radius: @border-radius-1;
  border: 0.2px solid #010101;
}

input[type=range]:focus::-ms-fill-lower {
  background: @color-neutral-40;
}

input[type=range]::-ms-fill-upper {
  background: @color-neutral-30;
  border-radius: @border-radius-1;
  border: 0.2px solid #010101;
}

input[type=range]:focus::-ms-fill-upper {
  background: @color-neutral-40;
}

@keyframes capslockWarningSlideAnimation {
  from {
    opacity: 0.0;
    transform: translate(2em,0);
  }

  to {
    opacity: 1.0;
    transform: translate(0,0);
  }
}

.forms--capslock-warning {
  padding-right: 0.5rem;
  border-radius: 0;
  background-color: @color-alert;
  color: white;
  width: fit-content;
  height: 2.6rem;
  z-index: 10000;
  position: absolute;
  opacity: 0;
  transform: translate(1.5rem,0);
  display: none;
  line-height: 2.6rem;

  &.forms--capslock-warning-show {
    animation-name: capslockWarningSlideAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    display: block;
  }

  &:after {
    content: "";
    position: relative;
    top: -2.6rem;
    left: -1.3rem;
    border-style: solid;
    border-width: 1.3rem 1.3rem 1.3rem 0;
    border-color: transparent @color-alert;
    display: block;
    width: 0;
    z-index: 10000;
  }
}

.form--inline-field {
  .mixin-display-flex;
  padding-top: 0.5rem;

  label {
    .mixin-flex(0 0 auto);
    line-height: 2.5rem;
    padding-right: (@spacing/2);
    color: @color-neutral-40;
  }
}

span.form--description-links {
  float: right;
  padding-left: (@spacing/2);
}

pre.is-error {
  color: @color-alert;
}

p.form--download {
  background-color: @color-neutral-10;
}

div.form--create-group-name {
  .mixin-display-flex;
  select {
    width: 30%;
  }
  input {
    width: 70%;
  }
}

div.form--password {
  .mixin-display-flex;

  input.form--with-toggle-visibility[type=password],
  input.form--with-toggle-visibility[type=text] {
    border-radius: 2px 0 0 2px;
	border-right: 0;
  }

  a.js-toggle-visibility {
    .mixin-flex(1 0 3rem);
    line-height: 1.5rem;
    padding: 0.5rem 0.75rem;
    top: 0;
    background: white;
    border-radius: 0 @border-radius-1 @border-radius-1 0;
    border: 1px solid @color-neutral-30;
  }
}

fieldset.form--panel {
  padding: 0;
  
  h2 {
    margin: (@spacing/2) @spacing;

    & + small {
      display: block;
      margin: (-@spacing/2) (@spacing/2) (@spacing/2) @spacing;
    }
  }
}

@media @before-breakpoint-one {
  fieldset.form--panel {
    h2 {
      margin-left: (@spacing/2);

      & + small {
        margin-left: (@spacing/2);
      }
    }
  }
}
