// =========== Log

// Log overview
div.log--wrapper {
  list-style: none;
  margin: 0;
  padding-bottom: 5px;

  .sortable-placeholder {
    border: 2px dashed @color-neutral-30;
    background: transparent;
    height: 3em;
    margin-bottom: 2px;
  }
}

// Individual logs
div.log {
  .mixin-clearfix;
  margin: 2px 0;
}

div.log--summary {
  .mixin-display-flex;
  .mixin-flex-wrap(nowrap);
  background: @white;
  min-height: 3rem;
  padding: 0.7rem 0;
  position: relative;

  > small {
    padding: 0 0.75rem;
  }

  [class^=label--] {
    margin-right: 0.5rem;
  }

  p {
    .mixin-flex(1 1 auto);
    -webkit-box-flex: 1;

    padding: 0 0.75rem;
    margin-bottom: 0;
    max-height: 6rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      content: " ";
      height: 1.5rem;

      .mixin-gradient(top, rgba(255, 255, 255, 0), @white); //De rgba is nodig om een zwarte gradient te voorkomen in FF & safari
      background-color: transparent;
    }

    &.show-all {
      max-height: none;

      &:after {
        display: none;
      }
    }
  }

  a {
    float: right;
    margin-top: 2px;
  }
}

div.log--empty > div {
  background: @color-neutral-10;
  font-style: italic;
  border: dashed 1px @color-neutral-30;
  color: @color-neutral-40;

  p:after {
    display: none;
  }
}

// =========== Log week

div.log--day {
  padding-left: 40px;
  position: relative;

  &.is-today span.tasks-planning--day-title {
    background: @color-brand-50;
  }
}

span.log--day-title {
  text-align: center;
  line-height: 3.15rem;
  color: @white;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 38px;
  text-transform: uppercase;
  font-weight: 600;
  background: @color-neutral-40;
  -webkit-backface-visibility: hidden;
  min-height: 3rem;
}
