// =========== jQuery UI

.ui-widget-overlay {
	opacity: 0.5;
	background: @color-neutral-50 50% 50% repeat-x;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100!important; //important needed to overwrite an element style
	@media @breakpoint-two {
		margin-left: @navigation-width;
	}
}

.ui-draggable,
.ui-sortable {
	-ms-touch-action: none;
}

div[role='status'] {
	display: none;
}

button.ui-multiselect.ui-widget {
	border-width: 1px;
}
