// =========== now

.now--notifications.tasks {
	background: @color-brand-50;
	border-radius: @border-radius-2;
	padding: 0.75rem 1rem;
	margin-bottom: @spacing;

	&:empty {
		display: none;
	}

	.is-open .popout {
		margin-bottom: 0;

		dl {
			margin-bottom: 0;
		}
	}

	.task {
		margin-bottom: 1.5rem;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.now--wrapper {
	.content--part {
		@media @breakpoint-one {
			padding: 0 @spacing;
		}

		@media @breakpoint-four {
			.mixin-grid(24, 0.5, 0.5);
		}

		&:nth-child(2) {
			border-top: 1px solid @color-neutral-40;
			padding-top: @spacing;

			@media @breakpoint-four {
				border-top: none;
				border-left: 1px solid @color-neutral-40;
				padding: 0 0 0 @spacing*2;
			}
		}

		@media @before-breakpoint-one {
			small.now--link a {
				padding-left: (@spacing/2);
			}
		}
	}
}

.now {
	.mixin-display-flex;
	.mixin-flex-wrap(wrap);
	background: @white;
	margin-bottom: (@spacing / 2);
	position: relative;
	border-bottom: 1px solid @color-neutral-20;

	> a:first-child {
		.mixin-display-flex;
		.mixin-flex-wrap(inherit);
		width: 100%;
	}

	p {
		margin: 0;
	}
}

div.now--body {
	.mixin-flex(1 1 auto);
	width: 100%;
	padding: 0.75rem 1rem;
	font-weight: normal;
	overflow: hidden;
}
