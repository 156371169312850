// =========== Buttons

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

// Choose a div or ul to group up your buttons
div.buttons {
  list-style: none;
  margin: 0 0 @spacing;
  .mixin-clearfix;
}

div.buttons-large {
  position: relative;
  top: -1px;
  padding: 0.75rem;
  box-shadow: inset 0 1px 2px fade(#000, 25%);
  background: @color-neutral-30;
  .mixin-clearfix;

  @media @breakpoint-three {
    padding: 1.5rem;
  }
}

// The standard button has the @color-neutral colors
.include-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: @border-radius-1;
  cursor: pointer;
  display: inline-block;
  font-size: @font-size-20;
  font-weight: bold;
  text-transform: uppercase;
  font-family: @font-base;
  line-height: 1.5rem!important;
  padding: 0.35rem 1rem;
  border: 0;
  vertical-align: baseline;
  text-decoration: none;
  text-align: center;

  &.is-small {
    font-size: @font-size-10;
    padding: 0.2rem 0.65rem;
  }

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  // If the button has an icon
  &:before {
    margin-right: 0.5rem;
    position: relative;
    top: 0.05rem;
  }
}

.button,
button,
input[type=submit] {
  .include-button;
  background-color: @color-brand-purple;
  color: @white;
  border: 2px solid @color-brand-purple;
  -webkit-font-smoothing: antialiased;
  position: relative;

  &:hover {
    color: @white;

    &:not(.is-disabled) {
      background: @color-brand-darkpurple;
      border: 2px solid @color-brand-darkpurple;
    }
  }

  &:active:not(.is-disabled),
  &:focus:not(.is-disabled) {
    background: @color-brand-purple;
    border-color: @color-brand-purple;
  }
}

// The detail button
.button-detail,
button.button-detail,
input[type=submit].button-detail {
  .include-button;
  color: @white;
  background-color: @color-brand-purple;
  border: 2px solid @color-brand-purple;
  -webkit-font-smoothing: antialiased;

  &:hover:not(.is-disabled) {
    background: @color-brand-darkpurple;
    border: 2px solid @color-brand-darkpurple;
    color: @white;
  }

  &:active:not(.is-disabled),
  &:focus:not(.is-disabled) {
    background: @color-brand-purple;
    border-color: @color-brand-purple;
  }
}

// The action button
.button-action,
button.button-action,
input[type=submit].button-action {
  .include-button;
  color: @color-neutral-60;
  background-color: @color-action-60;
  border: 2px solid @color-action-60;
  -webkit-font-smoothing: antialiased;

  &:hover:not(.is-disabled) {
    background: @color-action-50;
    color: @color-neutral-90;
  }

  &:active:not(.is-disabled),
  &:focus:not(.is-disabled) {
    background: @color-action-70;
    border-color: @color-action-70;
  }
}

// The soft grey button
a.button-soft,
button.button-soft,
input[type=submit].button-soft {
  .include-button;
  font-weight: 600;
  color: @color-neutral-50;
  border: 2px solid @color-neutral-30;
  background-color: transparent;
  background-image: none;

  &:hover:not(.is-disabled) {
    border-color: @color-neutral-30;
    background: @color-neutral-30;
    color: @color-neutral-70;
  }

  &:active:not(.is-disabled),
  &:focus:not(.is-disabled) {
    background: @color-neutral-40;
    border-color: @color-neutral-40;
  }
}

.button-webstore {
  padding: 0.5rem 0.7rem;
  float: left;
}

.button--after:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1rem;
  display: block;
  bottom: -1rem;
  right: 0;
  left: 0;
  cursor: default;

  @media @breakpoint-two {
    height: 1.5rem;
    bottom: -1.5rem;
  }
}

//Toggle buttons
ul.button--toggle {
  .mixin-display-flex;
  .mixin-flex-direction(row);
  .mixin-flex-wrap(nowrap);
  .mixin-justify-content(center);
  .mixin-align-items(stretch);
  .mixin-align-content(stretch);

  .mixin-clearfix;
  list-style: none;
  margin: 0 0 0.75rem;
  font-size: @font-size-20;
  text-transform: uppercase;

  @media @breakpoint-two {
    margin-bottom: 1rem;
  }

  a {
    display: block;
    font-weight: bold;
    background: @color-neutral-30;
    color: @color-neutral-70;
    line-height: 1.5rem !important;
    padding: 0.35rem 1rem;
    cursor: pointer;

    &:hover {
      background: @color-neutral-40;
    }

    small {
      color: inherit;
      opacity: 0.5;
    }
  }

  li {
    .mixin-flex(1 1 auto);
    -webkit-box-flex: 1;
    .mixin-align-items(stretch);
    margin-bottom: 2px;
    text-align: center;
    position: relative;

    + li {
      margin-left: 2px;
    }
  }

  li:first-child a {
    border-radius: @border-radius-2 0 0 @border-radius-2;
  }

  li:last-child a {
    border-radius: 0 @border-radius-2 @border-radius-2 0;
    margin-right: 0;
  }

  li.is-selected a {
    color: @white;
    background: @color-brand-50;

    &:hover {
      .mixin-gradient(top, @color-brand-60, @color-brand-50);
    }
  }

}
