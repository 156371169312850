// ============ Vaults

.vaults--shares {
	.mixin-display-flex;

	.vaults--shares-list {
		.mixin-flex(1 1 auto);
		padding-left: (@spacing/2);
	}
}

.vaults--name-color .form--fields {
	.mixin-display-flex;
	.mixin-flex-wrap(wrap);

	> select {
		width: 25%;
	}

	> input {
		width: 75%;
	}

	.form--errors {
		width: 100%;
	}
}

.vaults--enddate .form--fields {
	.mixin-display-flex;
	.mixin-flex-wrap(wrap);

	@media @before-breakpoint-one {
		.mixin-flex-direction(column);
	}

	> select {
		min-width: 20em;

		@media @before-breakpoint-one {
			margin-top: 1em;
		}
		@media @breakpoint-one {
			width: ~"calc(100% - 7em)";
		}
	}

	> input {
		min-width: 7em;

		@media @breakpoint-one {
			width: 7em;
		}
	}

	.form--errors {
		width: 100%;
	}
}

.form--fields {
	> input.vaults--secret {
		padding-right: 2.5rem;
		font-family: monospace;

		~ i.vaults--secret-toggle-password.icon {
			right: 2.65rem;
			color: @color-neutral-30;
			cursor: pointer;
			float: right;
			left: auto;

			&.is-show-secret {
				color: @color-neutral-50;
			}
		}

		~ i.vaults--secret-copy-password.icon {
			right: 0.65rem;
			color: @color-brand-60;
			cursor: pointer;
			float: right;
			left: auto;
		}
	}

	.vaults--secret-totp {
		float: right;

		> span {
			font-weight: 400;
			color: @color-neutral-60;
		}

		> a.vaults--secret-totp-refresh {
			display: none;
		}

		&.is-totp-expired {
			> span {
				text-decoration: line-through;
			}

			> a.vaults--secret-totp-copy {
				display: none;
			}

			> a.vaults--secret-totp-refresh {
				display: inline;
			}
		}
	}
}

.vaults--color *,
.vaults--color {
	&.is-group-green {
		background: @color-success;
	}

	&.is-group-red {
		background: @color-alert;
	}

	&.is-group-blue {
		background: @color-brand-50;
	}

	&.is-group-dark {
		background: @color-neutral-50;
	}
	
	&.is-group-pink-lavender {
		background: @color-pink-lavender;
	}
	
	&.is-group-crimson-red {
		background: @color-crimson-red;
	}
	
	&.is-group-middle-yellow {
		background: @color-middle-yellow;
	}
	
	&.is-group-android-green {
		background: @color-android-green;
	}
	
	&.is-group-sage {
		background: @color-sage;
	}
	
	&.is-group-artichoke {
		background: @color-artichoke;
	}
}

.vaults--password-strength-indicator {
	display: inline-flex;
	border-width: 1.5px;
	border-style: solid;
	border-radius: 2px;
	width: 2rem;
	height: 0.65rem;

	&.is-strength-0 {
		border-color: @color-alert;
		.vaults--password-strength {
			flex-basis: 0%;
			background-color: @color-alert;
		}
	}

	&.is-strength-1 {
		border-color: @color-alert;
		.vaults--password-strength {
			flex-basis: 5%;
			background-color: @color-alert;
		}
	}

	&.is-strength-2 {
		border-color: @color-alert;
		.vaults--password-strength {
			flex-basis: 10%;
			background-color: @color-alert;
		}
	}

	&.is-strength-3 {
		border-color: @color-alert;
		.vaults--password-strength {
			flex-basis: 15%;
			background-color: @color-alert;
		}
	}

	&.is-strength-4 {
		border-color: @color-alert;
		.vaults--password-strength {
			flex-basis: 20%;
			background-color: @color-alert;
		}
	}

	&.is-strength-5 {
		border-color: @color-alert;
		.vaults--password-strength {
			flex-basis: 25%;
			background-color: @color-alert;
		}
	}

	&.is-strength-6 {
		border-color: @color-alert;
		.vaults--password-strength {
			flex-basis: 30%;
			background-color: @color-alert;
		}
	}

	&.is-strength-7 {
		border-color: @color-warning;
		.vaults--password-strength {
			flex-basis: 35%;
			background-color: @color-warning;
		}
	}

	&.is-strength-8 {
		border-color: @color-warning;
		.vaults--password-strength {
			flex-basis: 40%;
			background-color: @color-warning;
		}
	}

	&.is-strength-9 {
		border-color: @color-warning;
		.vaults--password-strength {
			flex-basis: 45%;
			background-color: @color-warning;
		}
	}

	&.is-strength-10 {
		border-color: @color-warning;
		.vaults--password-strength {
			flex-basis: 50%;
			background-color: @color-warning;
		}
	}

	&.is-strength-11 {
		border-color: @color-warning;
		.vaults--password-strength {
			flex-basis: 55%;
			background-color: @color-warning;
		}
	}

	&.is-strength-12 {
		border-color: @color-warning;
		.vaults--password-strength {
			flex-basis: 60%;
			background-color: @color-warning;
		}
	}

	&.is-strength-13 {
		border-color: @color-android-green;
		.vaults--password-strength {
			flex-basis: 65%;
			background-color: @color-android-green;
		}
	}

	&.is-strength-14 {
		border-color: @color-android-green;
		.vaults--password-strength {
			flex-basis: 70%;
			background-color: @color-android-green;
		}
	}

	&.is-strength-15 {
		border-color: @color-android-green;
		.vaults--password-strength {
			flex-basis: 75%;
			background-color: @color-android-green;
		}
	}

	&.is-strength-16 {
		border-color: @color-android-green;
		.vaults--password-strength {
			flex-basis: 80%;
			background-color: @color-android-green;
		}
	}

	&.is-strength-17 {
		border-color: @color-android-green;
		.vaults--password-strength {
			flex-basis: 85%;
			background-color: @color-android-green;
		}
	}

	&.is-strength-18 {
		border-color: @color-android-green;
		.vaults--password-strength {
			flex-basis: 90%;
			background-color: @color-android-green;
		}
	}

	&.is-strength-19 {
		border-color: @color-android-green;
		.vaults--password-strength {
			flex-basis: 95%;
			background-color: @color-android-green;
		}
	}
}

.vaults--panel {
	span.vaults--color {
		display: block;
		width: (@spacing/2);
		position: absolute;
		top: (-@spacing/2);
	}

	i.icon {
		&.vaults--icon-error {
			color: @color-alert;
		}

		&.vaults--icon-warning {
			color: @color-warning;
		}
	}
}

@media @breakpoint-one {
	.vaults--panel li {
		> * {
			&.vaults--name {
				flex-grow: 10;
			}

			&.vaults--share-info {
				overflow: visible;
				flex-grow: 0;
				min-width: 2rem;
			}

			&.vaults--icon2-table {
				overflow: visible;
				flex-grow: 0;
				min-width: 2rem;
			}

			&.vaults--url {
				flex-grow: 0;
				min-width: 2rem;

				> span {
					display: none;
				}
			}
			
			&.vaults--vaultname {
				flex-grow: 7;
			}

			&.vaults--date {
				flex-grow: 3;
			}

			&.vaults--username {
				flex-grow: 5;
			}

			&.vaults--password {
				flex-grow: 0;
				flex-basis: 2rem;
			}

			&.vaults--totp {
				flex-grow: 0;
				flex-basis: 2rem;
			}

			&.vaults--file {
				flex-grow: 0;
				flex-basis: 2rem;
			}

			&.vaults--edit {
				display: none;
			}
		}
	}

	span.vaults--color {
		bottom: (-@spacing/2);
		left: 0;
	}
}

.vaults--panel {
	li > * {
		&.vaults--file,
		&.vaults--password,
		&.vaults--totp {
			.vaults--secret-label {
				display: none;
			}

			@media @before-breakpoint-one {
				.vaults--secret-label {
					display: inline;
				}
			}
		}
	}
}

@media @breakpoint-four {
	.vaults--panel.is-full-page {
		li > * {
			&.vaults--file,
			&.vaults--password,
			&.vaults--totp {
				.vaults--secret-label {
					display: inline;
				}
			}
		}

		li > * {
			&.vaults--password {
				flex-grow: 0;
				flex-basis: 9rem;
			}

			&.vaults--totp {
				flex-grow: 0;
				flex-basis: 4rem;
			}

			&.vaults--file {
				flex-grow: 6;
				flex-basis: 0;
			}
		}

		li.is-secret-visible {
			.vaults--password,
			.vaults--url,
			.vaults--username,
			.vaults--vaultname {
				display: block;
			}

			.vaults--password.is-secret-visible {
				flex-grow: 10;
				flex-basis: 5rem;
			}

			.vaults--totp.is-secret-visible {
				flex-grow: 6;
				flex-basis: 4.5rem;
			}
		}
	}
}

@media @before-breakpoint-one {
	.vaults--panel {
		span.vaults--color {
			bottom: -20rem;
			right: 0;
		}
	}
}
