@media @before-breakpoint-one {
  .nestedgroups--panel.flextable--panel {
    li {
      .mixin-flex-direction(row);
      > *.nestedgroups--icon {
        display: block;
      }
    }
  }
}

a.nestedgroups--icon {
  flex-grow: 0;
  flex-basis: 3rem;
  text-align: center;
  padding-right: @spacing;
}


