.dashboard--graphs {
  .mixin-display-flex;
  padding-top: 0.25rem;

  @media @breakpoint-three {
    padding-top: 1.25rem;
  }
}

.dashboard--graph--3inline {
  height: 10rem;
  width: 33.33%;

  @media @breakpoint-one {
    height: 14rem;
  }

  @media @breakpoint-three {
    height: 19rem;
  }
}

.dashboard--graph--2inline {
  height: 10rem;
  width: 50%;

  @media @breakpoint-one {
    height: 14rem;
    width: 49%;
  }

  @media @breakpoint-three {
    height: 19rem;
    width: 49%;
  }
}

.dashboard--graph {
  height: 10rem;
  width: 80%;

  @media @breakpoint-one {
    height: 14rem;
  }

  @media @breakpoint-three {
    height: 19rem;
  }
}

.dashboard--tasks.panel {
  margin-top: ~"calc(-1.5rem - 2px)";
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid @color-neutral-10;
}
