// =========== Popover-tabs

.popover-tabs {
  ul {
    .mixin-display-flex;
    .mixin-flex-direction(row);
    .mixin-flex-wrap(nowrap);
    .mixin-justify-content(center);
    .mixin-align-items(stretch);
    .mixin-align-content(stretch);

    .mixin-clearfix;
    list-style: none;
    margin: 0 0 0.75rem;
    font-size: @font-size-20;
    text-transform: uppercase;

    @media @breakpoint-two {
      margin-bottom: 0.25rem;
    }

    a {
      display: block;
      font-weight: bold;
      background: @color-neutral-30;
      margin-right: 2px;
      color: @color-neutral-70;
      line-height: 1.5rem !important;
      padding: 0.35rem 1rem;
    }
  }

  li {
    .mixin-flex(1 1 auto);
    -webkit-box-flex: 1;
    .mixin-align-items(stretch);
    margin-bottom: 2px;
    text-align: center;
    position: relative;
  }

  li:first-child a {
    border-top-left-radius: @border-radius-2;
    border-bottom-left-radius: @border-radius-2;
  }

  li:last-child a {
    border-top-right-radius: @border-radius-2;
    border-bottom-right-radius: @border-radius-2;
    margin-right: 0;
  }

  li.is-selected a {
    color: @white;
    background: @color-brand-50;
  }

  li.is-selected.has-options:after {
    position: absolute;
    height: 0;
    width: 0;
    bottom: -5px;
    left: 50%;
    margin-left: -3px;
    content: " ";
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid @color-brand-50;
  }

  &:not(.is-disabled) {
    li.is-selected a:hover {
      .mixin-gradient(top, @color-brand-60, @color-brand-50);
    }

    li a:hover {
      background: @color-neutral-40;
    }
  }

  &.is-disabled a {
    color: @color-neutral-40;
    cursor: default;
  }
}
