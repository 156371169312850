// =========== Authenticator Signin

.authenticator-signin {
  fieldset.panel {
    background-color: transparent;
    border-bottom: 0;

    li {
      background-color: fade(@white, 10%);
      border-bottom: 0;
      margin-bottom: 2px;
    }

    label {
      color: @white;
    }
  }

  label {
    font-size: @font-size-20;
    padding: 0.5rem 0.5rem 0.35rem;
    color: fade(@white, 70%);
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;

    &:hover {
      color: @white;
    }
  }

  a:hover {
    color: @color-brand-30;
  }

  .frc-banner,
  .frc-icon,
  .frc-text,
  .frc-button {
    display: none;
  }

  .frc-container {
    height: 1rem;
    margin-top: -1rem;
  }

  .frc-progress {
    width: 100%;
    height: 0.5rem;
  }

  &.authenticator-signin--verifypow {
    .frc-container {
      height: auto;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .frc-progress {
      height: 1.5rem;
    }
  }
}

ul.authenticator-signin--links {
  -webkit-font-smoothing: antialiased;
  list-style: none;
  margin-left: 0;

  a {
    display: block;
    font-size: @font-size-20;
    padding: 0.5rem;
    color: fade(@white, 70%);
    border-bottom: 1px solid fade(@white, 10%);

    &:hover {
      color: @white;
    }
  }
}

ul.authenticator-signin--icons {
  .mixin-display-flex;
  .mixin-flex-wrap(nowrap);
  .mixin-align-items(center);
  .mixin-justify-content(flex-end);
  margin-bottom: 0;

  li {
    .mixin-flex(0 0 auto);
    width: 2.5rem;
    text-align: center;

    a {
      color: fade(@white, 70%);

      &:hover {
        color: @white;
      }
    }
  }
}
