// ============ Webhooks

table.webhooks--type-table {
	width: 100%;

	tr {
		text-align: center;

		th:not(:first-of-type) {
			width: 1rem;
		}

		td {
			padding: 0.5rem;
		}

		td:first-of-type {
			text-align: left;
		}
	}

	tfoot {
		td:last-of-type {
			text-align: right;
			border-bottom: 0;

			span {
				font-size: @font-size-20;
				padding-left: 1rem;
			}
		}
	}
}

ul.webhooks--type-list {
	li {
		line-height: 2rem;
	}
}

.webhooks--type-options label {
	padding-right: 2rem;
}

.webhooks--delivery {
	.task--summary {
		i:first-of-type {
			margin-right: 1rem;
		}
	}
}

.webhooks--error {
	.task--summary {
		color: @color-alert;
	}

	pre.webhooks--payload.is-response {
		background-color: @color-warning;
	}
}

.webhooks--delivery-time {
	float: right;
	padding-top: 0.5rem;
}

pre.webhooks--payload {
	background-color: @color-neutral-10;
	font-size: @font-size-20;
	padding: (@spacing/2);
	margin: 0 0 (@spacing/2);
	line-height: 1.3rem;
	color: black;
	white-space: pre-wrap;
	word-break: break-word;
}
