// =========== Dropdown

.has-dropdown {
  position: relative;

  a {
    cursor: pointer;
  }

  &:hover .dropdown--marker {
    display: block;
  }
}

.dropdown {
  display: none;
  position: absolute;
  z-index: 11;
  top: 2.25rem;
  font-size: @font-size-20;
  text-transform: none;
  white-space: nowrap;
  text-align: left;
  background-color: fade(@color-neutral-90, 95%);
  color: @white;
  //width: 14em;
  padding: 0.25rem;
  border-radius: @border-radius-2;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 0 8px fade(@color-neutral-70, 30%);

  &.is-open {
    display: block;
  }
}

.dropdown.dropdown--marker {
  top: 1.5rem;
}

.dropdown--border {
  border-top: 1px solid @color-neutral-40;
}

div.dropdown--date {
  .dropdown--border;
  display: block;
  padding: 0.5rem 1rem;
  font-size: @font-size-20;
  color: @color-neutral-40;
  font-weight: 400;
  margin: 0;
}

ul.dropdown--list {
  margin: 0;
  list-style: none;

  li {
    margin-bottom: 1px;
    display: block;
    padding: 0;
    border-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li.is-selected {
    background: @color-neutral-70;

    a {
      font-weight: 600;
      color: @color-brand-40;
    }

    .dropdown--options {
      display: block;
    }
  }

  a,
  span {
    display: block;
    padding: 0.5rem 1rem;
    color: @color-neutral-30;
    font-weight: 400;
    border-radius: @border-radius-1;
  }

  a:hover {
    color: @white;
    background: @color-neutral-70;
  }
}

span.dropdown--arrow {
  position: absolute;
  height: 0;
  width: 0;
  content: " ";
  display: block;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  &.is-top {
    top: -5px;
    border-bottom: 5px solid fade(@color-neutral-90, 95%);
  }

  &.is-bottom {
    bottom: -5px;
    border-top: 5px solid fade(@color-neutral-90, 95%);
  }
}

.dropdown--options {
  display: none;
  border-radius: 0 0 @border-radius-1 @border-radius-1;
  padding: 0 0.35rem 0.35rem;

  input {
    border: 0;

    &:first-child {
      border-radius: @border-radius-1 @border-radius-1 0 0;
    }

    &:last-child {
      border-radius: 0 0 @border-radius-1 @border-radius-1;
    }

    &:only-child {
      border-radius: @border-radius-1;
    }
  }

  input.is-error {
    box-shadow: inset 0 0 0 1px @color-action-70;
  }

  p.is-error-comment {
    margin: 0 2px;
    color: @color-action-70;
    font-size: @font-size-20;
  }
}

ul.linked-groups > li {
  display: inline-block;
}
