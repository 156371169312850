// =========== Navigation-header

.navigation-header {
  .mixin-flex(0 0 auto);
  -webkit-box-flex: 0;
  position: relative;
  z-index: 9;
  padding: 1.5rem 3.5rem 0.5rem;
  width: 100%;

  @media @breakpoint-two {
    width: @navigation-width;
  }

}

.navigation-header--logo {
  img {
    display: inline-block;
    width: 100%;
  }

  a {
    color: @white;
    .mixin-display-flex;
    .mixin-align-items(center);
    font-size: @font-size-60;
    font-weight: 300;
  }
}
