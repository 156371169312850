// =========== States

.is-hidden {
  display: none !important;
}

.is-hidden,
.is-invisible {
  visibility: hidden !important;
}

.is-active,
.is-visible {
  display: block;
}

.left {
  float: left;
}

.right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-wrap-anywhere {
  word-break: break-word;
}

.nowrap {
  white-space: nowrap;
}

.font-is-small {
  font-size: @font-size-20;
}

.is-inactive {
  color: @color-neutral-40 !important;

  &:hover {
    color: @color-neutral-40 !important;
    background: initial !important;
    cursor: text;
  }

  i.icon:before {
    margin-left: 0.35rem;
  }
}

.is-alt-text {
  display: none;
}

.is-clickable {
  cursor: pointer;
}

.page-is-empty {
  text-align: center;
  display: block;
  font-style: italic;
}

.page-intro {
  text-align: center;
  display: block;
  font-style: italic;
}

.add-spacing {
  margin-top: @spacing;
}
