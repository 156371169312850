// ============ Audit

@media @before-breakpoint-one {
  .groupaudit--panel.flextable--panel {
    div.flextable--item {
      .mixin-flex-direction(row);
      .mixin-flex-wrap(wrap);

      > * {
        display: block;

        &.groupaudit--name {
          flex-grow: 1;
          max-width: ~"calc(100% - 10.15rem)";
          padding-right: 0;

          &.is-display {
            max-width: ~"calc(100% - 4rem)";
          }
        }

        &.groupaudit--nested-name {
          flex-grow: 1;
          max-width: ~"calc(100% - 4rem)";
          padding-right: 0;
        }

        &.groupaudit--comment,
        &.groupaudit--manager {
          flex-grow: 0;
          flex-basis: 2rem;
        }

        &.groupaudit--controls {
          flex-grow: 0;
          flex-basis: 7rem;
          padding: 0;
          margin-top: -0.3rem;
          margin-bottom: -0.3rem;

          label {
            padding: 0.1rem 0.4rem;
          }
        }

        &.groupaudit--action {
          min-width: 100%;
          order: 1;
        }

        &.groupaudit--enddate,
        &.groupaudit--lastactive {
          min-width: 100%;
          order: 2;
        }
      }
    }
  }
}

@media @breakpoint-one {
  .groupaudit--panel div.flextable--item {
    > * {
      &.groupaudit--name {
        flex-grow: 4;
      }

      &.groupaudit--comment,
      &.groupaudit--manager {
        flex-grow: 0;
        flex-basis: 3rem;
      }

      &.groupaudit--enddate,
      &.groupaudit--lastactive {
        flex-basis: 3rem;
        flex-grow: 2;
      }

      &.groupaudit--controls {
        flex-grow: 0;
        flex-basis: 10rem;
      }

      &.groupaudit--action {
        flex-grow: 5;
      }
    }
  }
}

.groupaudit--panel {
  .groupaudit--item {
    .mixin-clearfix;
    background-color: @color-neutral-10;

    &.disabled {
      color: @color-neutral-30;
    }

    .flextable--item {
      background-color: white;
    }

    .groupaudit--controls {
      line-height: 2.5rem;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;
    }

    .groupaudit--comment,
    .groupaudit--manager {
      text-align: center;
      padding: 0;
    }

    &.audit--hidden-accounts {
      padding-top: 2px;

      a {
        padding-left: 1.5rem;
        padding-top: 0.75rem;
        border-top: 1px solid @color-neutral-10;
      }
    }
  }

  .flextable--item {
    background-color: white;
  }
}

.groupaudit--action-options {
  label {
    cursor: pointer;
    padding: 0.4rem 1rem;
    border-top: @color-neutral-30 2px solid;
    border-bottom: @color-neutral-30 2px solid;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;

    background-color: white;
    color: @color-neutral-30;

    &:nth-of-type(1) {
      border-left: @color-neutral-30 2px solid;
      border-radius: 5px 0 0 5px;
    }

    &:nth-last-child(1) {
      border-right: @color-neutral-30 2px solid;
      border-radius: 0 5px 5px 0;
    }

    &.groupaudit--action-green:hover {
      background-color: @color-success;
      color: white;
    }

    &.groupaudit--action-yellow:hover {
      background-color: @color-warning;
      color: white;
    }

    &.groupaudit--action-red:hover {
      background-color: @color-alert;
      color: white;
    }

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }

  input {
    &:disabled {
      &:checked + label {
        background-color: @color-neutral-30;
        color: white;

        &:hover {
          background-color: @color-neutral-30;
          color: white;
        }

        &:nth-of-type(1) {
          border-color: @color-neutral-30;
        }

        &:nth-last-child(1) {
          border-color: @color-neutral-30;
        }
      }

      background-color: white;
      color: @color-neutral-30;

      + label {
        cursor: default;

        &:hover {
          background-color: white;
          color: @color-neutral-30;
        }
      }
    }

    &:checked + label {
      &.groupaudit--action-green {
        background-color: @color-success;
        color: white;
        border-color: @color-success;
      }

      &.groupaudit--action-yellow {
        background-color: @color-warning;
        color: white;
        border-color: @color-warning;
      }

      &.groupaudit--action-red {
        background-color: @color-alert;
        color: white;
        border-color: @color-alert;
      }
    }

    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
