// =========== Messages

ul.feedbackPanel {
  list-style: none;
  margin: 0;
  padding: 0 0.5rem;
  text-align: center;
  margin: 0;

  @media @breakpoint-two {
    left: @navigation-width;
  }

  li {
    position: relative;
    margin: 0.5rem 0;
    transition: margin 1s, opacity 1s;
    -webkit-transition: margin 1s, opacity 1s;

    &.is-hidden-feedback {
      margin-top: 0;
      opacity: 0;
      z-index: -10;
    }
  }
}

.include-message {
  display: block;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.35rem;
  vertical-align: baseline;
  margin-bottom: 2px;
  border-radius: @border-radius-1;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  i {
    float: right;
    padding-top: 2px;
  }
}

li.feedbackPanelINFO {
  .include-message;
  background: @color-neutral-50;
  color: @white;

  a {
    margin-left: (@spacing/2);
    color: @color-brand-30;

    &:hover {
      color: @color-brand-40;
    }
  }
}

li.feedbackPanelSUCCESS {
  .include-message;
  background: @color-success;
  color: @white;
  -webkit-font-smoothing: antialiased;

  a.is-undobutton {
    color: @color-brand-70;
    margin-left: (@spacing/2);

    &:hover {
      color: @color-brand-60;
    }
  }
}

li.feedbackPanelERROR {
  .include-message;
  background: @color-alert;
  color: @white;
  -webkit-font-smoothing: antialiased;
}

div.feedbackPanel--test-report {
  position: fixed;
  background: @white;
  right: 0.5rem;
  top: 9.3rem;
  max-height: 80%;
  z-index: 1000;
  width: 30rem;
  border-radius: @border-radius-2;
  box-shadow: 0 1px 1px fade(@black, 10%);
  border: 1px solid @color-neutral-10;
  margin: 0;
  padding: 1rem;
  overflow-y: auto;

  a {
    position: absolute;
    right: (@spacing/2);
    top: (@spacing/2);
  }

  pre {
    font-size: @font-size-20;
    white-space: pre-wrap;
    line-height: 1.2rem;
  }
}
