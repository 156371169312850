// =========== Popover

.popover {
  .mixin-transform(translateY(-350%));
  position: fixed;
  z-index: 200;
  overflow-y: auto;
  top: 4%;
  max-height: 94%;
  padding: 0;
  margin: 0 1rem;
  background: @white;
  box-shadow: 0 0 8px -1px fade(@color-neutral-70, 80%);

  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: -moz-transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

  @media @breakpoint-one {
    top: 15%;
    max-height: 80%;
    left: 50%;
    margin-left: -17rem;
    width: 34rem;
  }

  &.is-open {
    .mixin-transform(translateY(0));

    +.overlay {
      position: fixed;
      z-index: 103;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  &.is-way-too-large {
    top: 5%;
    max-height: 95%;
  }

  div.popover--title-bg {
    background-color: @color-brand-purple;
    padding: 1.67rem 1.67rem 2rem;

    h2 {
      color: @white;
      font-size: 1.33rem;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 1.5rem;
    }

    p {
      color: @white;
      font-size: 1rem;
      font-weight: 300;
      margin-bottom: 0;

      strong {
        display: inline;
      }
    }

    &.is-authentication {
      background-color: @color-brand-50;
    }
  }

  div.buttons {
    margin: 0;
    padding: 1rem;

    a,
    button {
      margin-bottom: 0;
    }
  }

  ul.feedbackPanel {
    position: static;
    padding: 0;
  }

  a.button,
  a.button-soft {
    margin-bottom: 0;
  }
}

.popover--panel {
  .mixin-clearfix;
  padding: 0.75rem;
  width: 100%;

  @media @breakpoint-two {
    padding: 1rem 1rem 0;
  }

  &:last-child {
    border-radius: 0 0 @border-radius-1 @border-radius-1;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }

  .has-double {
    .mixin-display-flex;
    .mixin-justify-content(space-between);
    .mixin-align-items(center);

    > :first-child {
      margin-right: 2px;
    }

    .is-half {
      width: 50%;
    }

    .is-one-third {
      width: 33.3%;
    }

    .is-two-third {
      width: 66.6%;
    }
  }

  h3 {
    display: block;
    color: @color-neutral-40;
    text-transform: uppercase;
    font-weight: 600;
    font-size: @font-size-20;
  }

  a[disabled] {
    color: @color-neutral-40;
    cursor: default;

    &:hover {
      color: @color-neutral-40;
    }
  }

  p {
    margin-bottom: (@spacing/2);
  }

  label {
    font-weight: 600;
  }

  .popover--select {
    width: 100%;

    select {
      height: 2.4rem;
    }

    i.icon {
      top: 3rem;
      right: 0.65rem;
      float: right;
      position: absolute;
    }
  }
}

.popover--field {
  .mixin-clearfix; //needs to be here because of overwrite issues with _forms.
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;

  &.has-number span {
    position: absolute;
    top: 0.58rem;
    left: 0.51rem;
    width: 20px;
    height: 20px;
    border-radius: @border-radius-1;
    background: @color-neutral-10;
    color: @color-neutral-40;
    text-align: center;
    font-weight: 600;
    line-height: 18px;
  }

  &.has-checkmark p {
    background: @white;
  }

  &.form--error input {
    border: 1px solid @color-alert;
  }

  > i.icon {
    position: absolute;
    top: 2rem;
    left: 0.65rem;
    color: @color-neutral-30;
  }

  &.has-label > i.icon {
    top: 2.2rem;
  }

  +.file {
    width: 100%;
    border-bottom: 1px solid @color-neutral-10;
    padding-bottom: 0.5rem;
    color: @color-neutral-60;

    label {
      display: inline-block;

      i {
        margin-right: 0.5rem;
        color: @color-neutral-60;
      }
    }

    a {
      float: right;
      color: red;
    }
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    width: 100%;
    color: white;
    font-size: 0.867rem;
    font-weight: bold;
    border-radius: 2px;
    text-transform: uppercase;
    line-height: 1.5rem;
    padding: 0.35rem 1rem;
    text-align: center;
    background-color: @color-neutral-60;
    display: inline-block;
    margin-top: 0;
  }

  .inputfile + label:hover,
  .inputfile:focus + label {
    background-color: @color-neutral-70;
  }

  input:focus ~ i,
  textarea:focus ~ i {
    color: @color-neutral-50;
  }

  input[type=email],
  input[type=number],
  input[type=password],
  input[type=searh],
  input[type=tel],
  input[type=text],
  input[type=url],
  label.checkbox,
  p,
  select,
  textarea {
    width: 100%;

    &:focus {
      border-color: @color-neutral-40;
      color: @color-neutral-70;
      outline: 0;
    }

    &:disabled {
      box-shadow: none;
      background: @color-neutral-10;
      color: @color-neutral-40;
    }
  }

  span.select2 {
    margin-right: 0;
  }

  input[type=checkbox] {
    position: relative;
    top: -1px;
  }

  label.checkbox {
    padding-left: 0.5rem;
  }

  textarea {
    min-height: 7rem;
  }

  label.checkbox,
  p {
    display: block;
    margin-bottom: 0;
    font-weight: 300;
  }

  .radio label {
    float: left;
  }

  ul {
    margin-bottom: 0;
  }

  > label {
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-weight: 600;
  }

  i.dropdown-arrow {
    float: right;
    margin-right: 0.4rem;
    margin-top: -2rem;
    pointer-events: none;
  }
}

.popover--dl {
  margin-bottom: 2px;
  padding: 0.75rem;
  background: @color-neutral-10;

  &:first-child {
    border-radius: @border-radius-1 @border-radius-1 0 0;
  }

  &:last-child {
    border-radius: 0 0 @border-radius-1 @border-radius-1;
  }

  @media @breakpoint-two {
    padding: 0.75rem 1rem;
  }

  h3 {
    display: block;
    color: @color-neutral-40;
    text-transform: uppercase;
    font-weight: 600;
    font-size: @font-size-20;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.popover--upload {
  .mixin-align-items(center);
  width: 100%;
  border: none;
  border-bottom: 1px solid @color-neutral-10;
  box-shadow: none;
  text-align: left;

  p {
    color: @color-neutral-60;
    border: 1px solid @color-neutral-30;
    box-shadow: inset 0 1px 2px @color-neutral-10;
    border-radius: @border-radius-1;
    margin-bottom: 0;
    line-height: 1.5em;
    padding: 0.5em;
  }

  .popover--field {
    .form--upload;
    width: 100%;
    border: none;
    border-bottom: 2px solid @color-neutral-10;
    box-shadow: none;
  }

  a {
    position: relative;
    z-index: 100;
    float: right;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }

  input {
    position: absolute;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .is-download {
    padding: 0 (@spacing/2);
    cursor: pointer;

    &:hover {
      color: @color-neutral-90;
    }
  }
}

.popover--input-subtitle {
  float: right;
  font-weight: normal;
  font-size: @font-size-20;

  i {
    vertical-align: middle;
  }
}

span.popover--label {
  position: absolute;
  top: 0.5rem;
  left: 0;
  left: 3.5rem;
  display: block;
  padding: 0.35rem 1.25rem 0.35rem 0.35rem;
  border-radius: @border-radius-1 * 3;
  background: @color-brand-50;
  color: @white;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: @font-size-20;
  line-height: 1;

  i.icon {
    position: absolute;
    top: 0.3rem;
    right: 0.15rem;
    left: auto;
    color: @white;
    cursor: pointer;
  }
}

.popover--qr-code {
  img {
    width: 100%;
    margin: 0;
    padding: 0 @spacing*2;
    background-color: @white;
  }
}

.popover--panel-lang-picker {
  .panel;

  padding: (@spacing/2);
  border-bottom: 0;

  @media @breakpoint-three {
    padding: (@spacing/2);
  }
}

.popover--lang-picker {
  h1 {
    vertical-align: middle;
    margin: 0.5rem (@spacing/2) 0.5rem 0.5rem;
    font-size: 1.75rem;
  }

  span {
    vertical-align: middle;
  }

  &.is-selected {
    background-color: @color-brand-30;
    border-radius: 2px;

    span {
      color: white;
    }
  }
}
