// =========== Panels

.panel {
  .mixin-clearfix;
  position: relative;
  background: @white;
  border-radius: @border-radius-1;
  margin-bottom: @spacing;
  padding: 0.75rem;
  border-bottom: 1px solid @color-neutral-30;

  @media @breakpoint-three {
    padding: 1.75rem;
  }

  dl {
    margin: 0 0 (@spacing/2);
  }

  pre {
    white-space: pre-wrap;
  }

  > *:last-child,
  > *:only-child {
    margin-bottom: 0;
  }
  
  h2 {
    font-size: @font-size-50;
    word-wrap: word-break;
	margin: -(@spacing/2) 0 (@spacing/2);
  }

  &.panel--header {
    .panel--collapse-bottom-margin;

    h2 {
      margin-bottom: 0.1rem;
    }
  }

  &.panel--collapse-bottom-margin {
    margin-bottom: 0;
    border-bottom: 1px solid @color-neutral-10;
  }

  &.panel--context-links {
    border: none;
    text-align: right;
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
    }

    a {
      padding: 1rem 1.75rem 1rem 0;
      display: block;
    }

    i {
      position: relative;
      top: 0.15rem;
    }
  }
}

.panel--collapse-bottom-margin .panel {
  margin-bottom: 0;
  border-bottom: 1px solid @color-neutral-10;
}

.panel--callout {
  .panel;
  background: @color-brand-50;
  color: @white;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;

  a {
    color: @white;
  }

  dl.advanced dt {
    color: @white;
  }
}

.panel--checkbox {
  position: absolute;
  bottom: (@spacing/2);
  right: (@spacing/2);

  @media @breakpoint-three {
    bottom: @spacing;
    right: @spacing;
  }

  span {
    margin-right: 1ex;
  }
}

.panel--link {
  position: absolute;
  top: (@spacing/2);
  right: (@spacing/2);

  @media @breakpoint-three {
    top: @spacing;
    right: @spacing;
  }
}

p.panel--warn {
  border-radius: @border-radius-1;
  padding: 0.5rem;
  font-weight: bold;
  min-height: 4rem;

  @media @breakpoint-three {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  > i.icon {
    float: left;
    color: @color-action-70;
    padding-right: 0.5rem;
    font-size: 2.5rem;

    @media @breakpoint-three {
      padding-right: 1.25rem;
    }
  }
}

p.panel--warn-popover {
  padding: @spacing @spacing 0;
  font-weight: bold;
  min-height: 4rem;
  .mixin-display-flex;
  margin-bottom: 0;

  > i.icon {
    .mixin-align-self(center);
    color: @color-action-70;
    padding-right: 0.5rem;
    font-size: 2.5rem;

    @media @breakpoint-three {
      padding-right: 1.25rem;
    }
  }
}

p.panel--warn-line {
  padding: 0.15rem 0.3rem 0.15rem @spacing;
  margin-bottom: (@spacing/2);
  font-size: @font-size-20;
  background-color: @color-warning;
  border-radius: @border-radius-1;
  color: @white;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
}

p.panel--attention {
  text-align: center;
  border: 2px solid #86949B;
  padding: 0.5rem;
}

.panel--large--attention {
	text-align: center;
	border: 2px solid #86949B;
	padding: 0.5rem;
	max-width: 70rem;
	margin: auto;
	margin-top: 5rem;
	padding: 1rem 2rem;

	li {
		padding-bottom: 1rem;
	}

	li:first-child {
		padding-top: 1rem;
	}

	ul {
		margin-bottom: 0;
	}
}
