// =========== Popout

.popout {
  background: @white;
  position: relative;
  margin-left: 6px;
  margin-right: 6px;
  z-index: 0;
  box-shadow: 0 0 8px fade(@color-neutral-70, 30%);

  display: none;
  opacity: 0;
  height: 0;

  -webkit-transform: translateY(-20px) scale(0.95);
  -moz-transform: translateY(-20px) scale(0.95);
  transform: translateY(-20px) scale(0.95);

  -webkit-transition: -webkit-transform 100ms ease-out, opacity 100ms ease-out;
  -moz-transition: -moz-transform 100ms ease-out, opacity 100ms ease-out;
  transition: transform 100ms ease-out, opacity 100ms ease-out;

  &:before {
    content: " ";
    position: absolute;
    top: -0.35rem;
    left: 3rem;
    border-top: 0;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    border-bottom: 0.4rem solid @white;
  }

  &.has-table:before {
    border-bottom: 0.4rem solid @color-neutral-30;
  }

  > *,
  > .referal--list .referal {
    border-bottom: 1px solid @color-neutral-10;

    &:last-child {
      border-bottom: 0;
    }

    &.referal--separator {
      border-top: 1px solid @color-neutral-10;
    }
  }

}

.has-popout {
  &.is-open .popout {
    overflow: visible;
    display: block;
    opacity: 1;
    height: auto;
    margin-top: 8px;
    margin-bottom: 8px;

    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }

  .popout--toggle {
    cursor: pointer;
  }
}

.popout--body {
  .mixin-clearfix;
  padding: 1rem;

  p:last-of-type {
    margin-bottom: 0;
  }

  p.is-material-link {
    text-align: right;
  }
}

.popout--actions {
  border-bottom: 0;
  position: relative;
  padding: 1rem;
  background: @color-neutral-10;
  .mixin-clearfix;

  p {
    margin: 0;
  }

  strong {
    display: block;
  }

  a.button {
    float: right;
  }

  label {
    font-weight: bold;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  + .popout--actions {
    padding-top: 0;
  }
}

.popout--formline {
  .mixin-clearfix;

  + .popout--formline {
    padding-top: 0.5rem;
  }
}

.popout--title {
  position: relative;
  padding: 1rem;
  background: @white;
  .mixin-clearfix;

  p {
    //float: left;
    margin: 0;
  }

  strong {
    display: block;
  }

  a.button {
    float: right;
  }
}

.popout--files {
  padding: 1rem;
  clear: both;

  p {
    margin-bottom: 0.5rem;
  }

  strong {
    margin-bottom: 0.5em;
    display: block;
  }
}

.popout--options {
  text-align: right;
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
  }

  a {
    padding: 1rem;
    display: block;
  }

  i {
    position: relative;
    top: 0.15rem;
    //margin-right: 0.35rem;
  }
  
  .popout--warning-feedback {
	padding: 1rem;
  }
}

.popout--materials {
  border-bottom: 3px solid @color-neutral-10;

  > * {
    border-bottom: 1px solid @color-neutral-10;
  }
}
