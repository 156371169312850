// =========== Navigation-footer

.navigation-footer {
  .mixin-flex(0 0 auto);
  -webkit-box-flex: 0;
  .mixin-align-self(flex-end);
  z-index: 9;
  position: relative;
  height: 6rem;
  width: 100%;

  @media @breakpoint-two {
    width: @navigation-width;
  }

  ul {
    .mixin-display-flex;
    list-style: none;
    background: fade(#000, 30%);
  }

  li {
    .mixin-flex(1 1 auto);
    text-align: center;
  }

  a {
    display: block;
    padding: 0.75rem;
    color: fade(@white, 40%);

    &:active {
      background: fade(#000, 30%);
    }

    @media @breakpoint-two {
      &:hover {
        color: fade(@white, 60%);
      }
    }
  }

  a.is-selected {
    color: @color-brand-50;
    font-weight: 700;
  }

  small.navigation-build {
    display: block;
    text-align: right;
    padding-right: 2rem;
    line-height: 3rem;
    color: fade(@white, 40%);
  }
}

li.navigation-footer--show-token {
  position: relative;

  div.dropdown {
    top: -2.5rem;
    left: 1.5rem;
    padding: 0.25rem 0.5rem;
  }
}

.navigation-footer--keyhub-logo {
  background-image: url("../images/logo-topicuskeyhub.svg");
  background-repeat: no-repeat;
  background-size: 5rem;
  background-position-y: center;
  background-position-x: 1rem;
}
