// ============ Manage access

table.adminactions {
  tr {}

  td {
    vertical-align: middle;
    padding-bottom: 1rem;

    &:first-child {
      padding-right: 1rem;
    }
  }

  a.button {
    width: 100%;
    white-space: nowrap;
  }
}
