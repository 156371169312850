/// =========== Layout

.l-full {
  @media @breakpoint-two {
    .mixin-grid(48, 0.5, 0.5);
  }
}

.l-three-fourth {
  @media @breakpoint-two {
    .mixin-grid(32, 0.5, 0.5);
  }
  @media @breakpoint-three {
    .mixin-grid(36, 0.5, 0.5);
  }
}

.l-two-third {
  @media @breakpoint-two {
    .mixin-grid(32, 0.5, 0.5);
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.l-half {
  @media @breakpoint-two {
    .mixin-grid(24, 0.5, 0.5);
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
    width: 50%;
  }
}

.l-one-third {
  @media @breakpoint-two {
    .mixin-grid(16, 0.5, 0.5);
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.l-one-fourth {
  @media @breakpoint-two {
    .mixin-grid(16, 0.5, 0.5);
  }
  @media @breakpoint-three {
    .mixin-grid(12, 0.5, 0.5);
  }
}

.l-wide {
  clear: both;

  @media @breakpoint-three {
    max-width: 70rem - (@spacing * 2);
    margin: auto;

    &.content {
      max-width: 70rem;
    }
  }
}

.l-medium {
  clear: both;

  @media @breakpoint-three {
    max-width: 60rem - (@spacing * 2);
    margin: auto;

    &.content {
      max-width: 60rem;
    }
  }
}

.l-small {
  clear: both;

  @media @breakpoint-three {
    max-width: 46rem - (@spacing * 2);
    margin: auto;

    &.content {
      max-width: 46rem;
    }
  }
}

.has-no-padding .l-medium,
.has-no-padding .l-small,
.has-no-padding .l-wide {

  @media @breakpoint-one {
    margin-left: @spacing;
    margin-right: @spacing;

  }

  @media @breakpoint-two {
    margin: auto;
  }
}

div.l-container {
  min-height: 100%;
  height: auto;
  width: 100%;

  // Flex module
  .mixin-display-flex; //create a flex layout
  .mixin-flex-direction(row);
  .mixin-flex-wrap(nowrap);
}

.l-first {
  clear: left;
}

.l-flex-content {
  .mixin-flex(1 1 auto);
  -webkit-box-flex: 1;

  .mixin-display-flex;
  .mixin-flex-direction(column);
  .mixin-flex-wrap(nowrap);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  @media @breakpoint-two {
    .mixin-order(2);
    margin-left: @navigation-width;
    z-index: 101;
    display: block;
    position: relative;
  }
}
