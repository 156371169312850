// =========== Signin

body.authenticator--background {
  background: @color-neutral-10;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

// Content
section.authenticator--wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  position: absolute;
  padding-right: 0;

  div.message {
    position: relative;
    z-index: 2;
  }

  &.error div.authenticator--body {
    max-width: 90%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

div.authenticator--body {
  color: @white;
  background: @color-neutral-70;
  padding: 1.5rem 2.5rem;
  position: relative;
  z-index: 2;
  max-width: 45rem;
  margin: auto;
  visibility: visible;
  border-radius: @border-radius-2;
  border-bottom: 1px solid @color-neutral-30;

  @media @breakpoint-one {
    margin-top: @spacing;
    margin-bottom: 3.75rem;
  }

  &.is-small {
    max-width: 32rem;
  }

  h1 {
    font-weight: 300;
    font-size: 1.2rem;
  }

  small {
    color: @color-neutral-30;
  }

  form {
    li {
      padding: 0.75rem;
    }
  }

  fieldset {
    margin-bottom: (@spacing / 3);
  }

  a.button,
  a.button-detail,
  input,
  select {
    box-shadow: 0 0 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid @color-neutral-30;

    &:first-of-type {
      border-radius: @border-radius-1 @border-radius-1 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 @border-radius-1 @border-radius-1;
      border: 0;
    }

    &:only-of-type {
      border-radius: @border-radius-1;
    }
  }

  a.button-action,
  a.button-soft {
    margin-bottom: @spacing;
    display: block;
    text-align: center;
  }

  .button-action {
    border: 0;
  }

  .button-soft {
    padding: ~"calc(0.35rem - 2px)" 1rem;
    color: @color-neutral-30;
  }

  div.authenticator--button-split {
    .mixin-display-flex;
	a {
      .mixin-flex(1 1 0);
      
      &:not(:last-child) {
        margin-right: @spacing;
      }
    }
  }

  a.authenticator--link {
    color: fade(@white, 70%);

    &:hover {
      color: @white;
    }
  }

  div.task--summary {
    background: none;
    border: 1px solid @color-neutral-40;
    border-radius: 2px;

    .task--summary-meta {
      background: none;
    }
  }

  div.popout {
    background: none;
    border: 2px solid @color-neutral-40;
    border-radius: 2px;

    .referal {
      background: none;
      border-bottom: 1px solid @color-neutral-50;
    }

    &:before {
      top: -0.4rem;
      border-bottom-color: @color-neutral-40;
    }
  }

  .referal--title {
    color: @color-neutral-10;
  }

  .authenticator--disclosure {
    ul {
      list-style: inherit;
      margin: 0 0 1.5rem 1.5em;
    }

    a {
      color: fade(@white, 70%);
      font-weight: normal;

      &:hover {
        color: @white;
      }
    }
  }

  .wizard .wizard--bubble {
    background-color: @color-neutral-70;
    color: @color-neutral-30;
    border-color: @color-neutral-30;
  }
}

img.authenticator--logo {
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto 1rem;
  display: block;

  &.is-small {
    width: 10rem;
    margin: 0 0 0 3rem;
    padding: 0;
    float: right;
  }

  &.is-paired {
    width: 20rem;
    margin: 0;
    padding: 0;
  }
}

img.authenticator--branding--logo {
  width: 16rem;
  margin: -3rem 0 0 11rem;
}

.authenticator--wrapper.error img.authenticator--logo {
  width: 16rem;
}

pre.authenticator--stacktrace {
  max-width: 90%;
  color: @white;
  background: @color-neutral-70;
  padding: 1.5rem;
  overflow: scroll;
  text-align: left;
  margin: 0 (@spacing/2) @spacing @spacing;
  min-height: 10rem;
  border-bottom-right-radius: @border-radius-2;
  border-bottom-left-radius: @border-radius-2;
  @media @breakpoint-two {
    overflow: auto;
    margin: 0 auto @spacing;
  }
}

.authenticator--qr-code img {
  width: 100%;
}

ul.authenticator--2fa-setup {
  .mixin-display-flex;

  li {
    flex-basis: 1rem;
    flex-grow: 1;

    a {
      .mixin-display-flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      border: 1px solid @color-neutral-50;
      padding: (@spacing/2);
      border-radius: @border-radius-1;

      &:hover {
        border-color: @color-brand-30;
      }
    }

    img {
      height: 10rem;
      width: 9rem;
      padding: @spacing;
    }
  }
}
