@font-face {
  font-family: 'Material Symbols Sharp';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/material-symbols-sharp.woff2') format('woff2'),
       url('../fonts/material-symbols-sharp.ttf') format('truetype');
}

.icon.material {
  .mixin-material-icon;
}

.mixin-material-icon {
  font-family: 'Material Symbols Sharp';
  font-weight: normal;
  font-style: normal;
  font-size: 1.2em;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  position: relative;
  top: 0.15em;
  font-variation-settings: 'opsz' 20, 'FILL' 0, 'GRAD' 0;
}

.icon.is-error {
  color: @color-alert;
}
